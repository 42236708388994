import axios from "axios";

const BASE_URL = "api/v1/categories";
const PUBLIC_URL = "api/v1/public/categories";

const fetch = () => axios.get(PUBLIC_URL);

const create = formData =>
  axios.post(BASE_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
const deleteCategory = id => axios.delete(`${BASE_URL}/${id}`);

const update = (id, formData) =>
  axios.put(`${BASE_URL}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const categoriesApi = {
  fetch,
  create,
  deleteCategory,
  update,
};

export default categoriesApi;
