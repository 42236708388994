import React from "react";

import { Dropdown, Typography, Checkbox, Tooltip } from "neetoui";

import TickSvg from "images/blackTick.svg";
import BoxSvg from "images/box.svg";
import MapSvg from "images/map.svg";

const { Menu, MenuItem } = Dropdown;

const ProductDesignTab = ({
  isPrintOnSide,
  allVariants,
  selectedVariants,
  handleVariantSelect,
  handleVarianceClick,
  gotToMaps,
  printOnSide,
  onPrintOnSide,
}) => (
  <>
    <Tooltip
      content="See product sizes and costs"
      followCursor="horizontal"
      position="top"
    >
      <>
        <Typography className="mx-3 mt-4 text-base font-semibold">
          Step 1 (Choose Sizes)
        </Typography>
        <Typography className="mx-3 py-1 text-xs font-normal text-gray-400">
          Shipping is Included.
        </Typography>
        <Typography className="mx-3 py-1 text-xs font-normal text-gray-400">
          Promo Codes entered in Payment Page.
        </Typography>
      </>
    </Tooltip>
    <div className="border-gray mx-3 mt-4 rounded border bg-white py-4 px-4 text-xs font-normal text-gray-500">
      <div className="mb-3 mt-1 flex items-center justify-between">
        <Dropdown
          className="shadow"
          buttonProps={{
            className: "custom-dropdown-button",
          }}
          buttonStyle="text"
          label="Choose Sizes"
          closeOnOutsideClick
        >
          <Menu>
            {allVariants.map((item, idx) => {
              const isSelected = selectedVariants[0]?.title === item.title;

              return (
                <MenuItem.Button
                  key={idx}
                  onClick={e => handleVariantSelect(e, item)}
                >
                  {isSelected ? <TickSvg /> : <BoxSvg />}
                  {`${item.title} : $${item.price}`}
                </MenuItem.Button>
              );
            })}
          </Menu>
        </Dropdown>
      </div>
      <div className="flex flex-wrap">
        {selectedVariants.map(variant => (
          <div
            key={variant.id}
            className="my-2 mx-3 flex h-9 cursor-pointer items-center justify-center bg-gray-100 p-2"
            onClick={() => handleVarianceClick(variant)}
          >
            <BoxSvg />
            <span className="ml-2">{`${variant.title} : $${variant.price}`}</span>
          </div>
        ))}
      </div>
    </div>
    <Tooltip
      content="Goes to our mapping page"
      followCursor="horizontal"
      position="top"
    >
      <Typography className="mx-3 mt-4 text-base font-semibold">
        Step 2 (Create Map)
      </Typography>
    </Tooltip>
    <div
      className="border-gray active-blue mx-3 my-4 flex cursor-pointer items-center rounded border bg-white p-3 text-xs font-medium"
      onClick={() => gotToMaps()}
    >
      <div className="mr-2">
        <MapSvg />
      </div>
      Create a map
    </div>
    {isPrintOnSide && (
      <div className="border-gray mx-3 my-4 bg-white p-3">
        <Checkbox
          label="Print on side"
          checked={printOnSide}
          onChange={onPrintOnSide}
        />
      </div>
    )}
  </>
);

export default ProductDesignTab;
