import React from "react";

import { Delete, Edit } from "neetoicons";
import { Button } from "neetoui";

export const columnData = ({
  setDeleteId,
  setCategory,
  setIsOpen,
  name = null,
  addSubcategory = () => {},
}) => [
  {
    title: name || "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Image",
    dataIndex: "url",
    key: "url",
    render: (_, row) => (
      <>
        {row.url && (
          <div
            style={{ width: 100, height: 100 }}
            className="flex items-center"
          >
            <img
              src={row.url}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        )}
      </>
    ),
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    align: "right",
    render: (_, row) => (
      <div className="flex items-center justify-end gap-4">
        {!name && (
          <Button
            label="Add subcategory"
            onClick={() => addSubcategory(row.id)}
          />
        )}
        <Edit
          onClick={() => {
            setIsOpen(true);
            setCategory(row);
          }}
        />
        <Delete onClick={() => setDeleteId(row.id)} />
      </div>
    ),
  },
];
