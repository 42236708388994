import React from "react";

import Footer from "components/Common/Footer";
import Header from "components/Common/Header";

import Content from "./Content";

const Catalogue = () => (
  <div className="h-screen w-full">
    <Header />
    <div className="catalogue m-auto">
      <Content />
      <Footer />
    </div>
  </div>
);

export default Catalogue;
