import React from "react";

import { Button } from "neetoui";
import { Input, Select } from "neetoui/formik";

const VariantList = ({
  variants,
  loadingVariants,
  isDisabled,
  showHelpText,
  push,
  addAllVariant,
  setFieldValue,
  variantList,
  handleRemoveVariant,
  remove,
}) => (
  <div className="flex flex-col space-y-3">
    {variants.map((variant, index) => (
      <div key={index} className="grid grid-cols-2 gap-4">
        <Select
          required
          name={`variants.${index}.variant`}
          label="Variant"
          options={variantList}
          isLoading={loadingVariants}
          placeholder="Select variant"
          isDisabled={isDisabled}
          helpText={showHelpText && "please select a Print provider"}
        />
        <div className="flex items-end space-x-4">
          <Input
            required
            label="Price"
            name={`variants.${index}.price`}
            placeholder="Enter price"
            disabled={isDisabled}
            helpText={showHelpText && "please select a Print provider"}
          />
          {variants.length > 1 && (
            <div>
              <Button
                label="Remove"
                onClick={() => {
                  remove(index);
                  handleRemoveVariant(variant);
                }}
              />
            </div>
          )}
        </div>
      </div>
    ))}
    <div>
      <Button
        label="Add more variants"
        onClick={() =>
          push({ variant: null, price: null, isNewlyCreated: true })
        }
        disabled={isDisabled}
      />
      <Button
        className="ml-2"
        label="Add all variants"
        onClick={() => addAllVariant(variants, setFieldValue)}
        disabled={isDisabled}
      />
    </div>
  </div>
);

export default VariantList;
