import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { Input, Button } from "neetoui";

import DeleteSvg from "images/delete-icon.svg";
import PencilSvg from "images/pencil.svg";

import { initialValue } from "./constant";
import { editOptions } from "./helper";
import ImageEditOptions from "./ImageEditOptions";

const noop = () => {};

const Card = ({
  title,
  canvas,
  showDot,
  onSelect,
  subTitle,
  showOptions,
  icon: SvgIcon,
  targetObject,
  setImageSize = noop,
  handleObjectPosition = noop,
  removeCanvasObject = noop,
  image,
  isMapImage,
  editImage,
  borderStyleDashed,
}) => {
  const [inputs, setInputs] = useState(initialValue);

  useEffect(() => {
    const activeObject = canvas?.getActiveObject();
    if (canvas && activeObject) {
      setInputs({
        rotate: 0,
      });
    }
  }, [canvas?.getActiveObject()]);

  const options = useMemo(() => editOptions(handleObjectPosition), []);

  const isActiveCard = targetObject === canvas?.getActiveObject();

  const changeInputValue = (e, option) => {
    const value = e.target.value;
    setInputs(prev => ({ ...prev, [option]: value }));
    setImageSize(option, value);
  };

  return (
    <>
      <div
        className={classNames(
          "my-4 cursor-pointer rounded border border-gray-200 bg-white py-4 px-5",
          {
            "border-dashed border-gray-500": borderStyleDashed,
          }
        )}
        onClick={onSelect}
      >
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            {image ? (
              <div className="h-12 w-12 overflow-hidden">
                <img src={image} />
              </div>
            ) : (
              <SvgIcon />
            )}
            <div className="ml-3">
              <div className="text-sm font-medium">{title}</div>
              <div className="text-xs font-normal">{subTitle}</div>
            </div>
          </div>
          {showDot && (
            <div className="flex justify-center">
              {isMapImage && (
                <div className="mt-1.5 cursor-pointer" onClick={editImage}>
                  <PencilSvg />
                </div>
              )}
              <div
                className="ml-2 cursor-pointer"
                onClick={e => {
                  e.stopPropagation();
                  removeCanvasObject();
                }}
              >
                <DeleteSvg />
              </div>
            </div>
          )}
        </div>
        {showOptions && isActiveCard && (
          <div className="grid grid-cols-2 gap-4 pt-4">
            <Button
              className="col-span-2 w-4/5 lg:col-span-1"
              label="Create Shape"
              onClick={() => handleObjectPosition("crop")}
            />
            <Input
              value={inputs.rotate}
              className="custom-input col-span-2"
              label="Rotate"
              placeholder="deg"
              suffix="deg"
              onChange={e => changeInputValue(e, "rotate")}
            />
            <ImageEditOptions options={options} />
          </div>
        )}
      </div>
      <div
        id="wdm_dpi_div"
        style={{
          height: "1in",
          left: "-100%",
          position: "absolute",
          top: "-100%",
          width: "1in",
        }}
      ></div>
    </>
  );
};

Card.defaultProps = {
  showOptions: true,
  showDot: true,
  handleObjectPosition: () => {},
  setImageSize: () => {},
};

export default Card;
