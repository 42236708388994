import React from "react";
import { useHistory } from "react-router-dom";
import LogoDark from "images/logo-dark.svg";

const Logo = () => {
  const history = useHistory();
  const handleRedirect = () => (window.location.href = process.env.HOME_URL);

  return (
    <div className="h-logo-cont w-1/3">
      <div
        onClick={handleRedirect}
        className="logo"
        style={{ width: "124px", height: "28px" }}
      >
        <LogoDark />
      </div>
      <span className="logo-sub">Builder</span>
    </div>
  );
};

export default Logo;
