import React, { useState, useEffect, useMemo } from "react";

import { Spinner, Typography } from "neetoui";
import { useHistory, useParams, useLocation } from "react-router-dom";

import libraryImagesApi from "apis/libraryImagesApi";
import mapApi from "apis/maps";
import { PAGE_LIMIT } from "common/constants";
import { ADD_MAP_PATH } from "components/routeConstants";
import { useMapDispatch } from "contexts/map";
import { useProductDispatch } from "contexts/product";

import AddObject from "./AddObject";
import HeaderTab from "./HeaderTabs";
import ListCanvasObject from "./ListCanvasObject";
import MyImages from "./MyImages";
import ProductDesignTab from "./ProductDesignTab";

const Sidebar = ({
  isLoggedIn,
  fileUploadRef,
  onAddTextClick,
  sideBarItems,
  selectCanvasObject,
  handleObjectPosition,
  setImageSize,
  canvas,
  onMapsClick,
  removeCanvasObject,
  background,
  setBackground,
  selectedVariants,
  handleVarianceClick,
  activeVariant,
  imageUploading,
  allVariants = [],
  setSelectedVariants,
  catalogId,
  setImageUploading,
  isLoading,
  imageLoading,
  isPrintOnSide,
  onPrintOnSide,
  printOnSide,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [showAddObjectMenu, setShowAddObjectMenu] = useState(true);
  const [maps, setMaps] = useState([]);
  const [isMapLoading, setIsMapLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [mapPage, setMapPage] = useState(1);
  const [mapTotalCount, setMapTotalCount] = useState();
  const [libraryPage, setLibraryPage] = useState(1);
  const [libraryTotalCount, setLibraryTotalCount] = useState();
  const [imageFile, setImageFile] = useState(null);

  const dispatch = useProductDispatch();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mapDispatch = useMapDispatch();

  useEffect(() => {
    if (imageFile) uploadLibraryImages(imageFile);
  }, [imageFile]);

  const onFileChange = event => setImageFile(event.target.files[0]);

  const uploadLibraryImages = async file => {
    try {
      setImageUploading(true);
      const formData = new FormData();
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = async () => {
        formData.append("height", img.naturalHeight);
        formData.append("width", img.naturalWidth);
        formData.append("file", file);
        formData.append("name", file.name);
        const {
          data: { id, image, name },
        } = isLoggedIn
          ? await libraryImagesApi.create(formData)
          : {
              data: {
                id: null,
                image: window.URL.createObjectURL(file),
                name: file.name,
              },
            };
        if (isLoggedIn) getImages(libraryPage);

        onMapsClick(
          {
            url: image,
            name,
            isLibrary: true,
            libId: id,
            file,
            blob: isLoggedIn ? null : file,
          },
          () => {
            setImageUploading(false);
          }
        );

        window.URL.revokeObjectURL(img.src);
      };
    } catch (err) {
      logger.error(err);
    }
  };

  const getMaps = async pageNumber => {
    try {
      setIsMapLoading(true);
      const {
        data: { maps, total_count },
      } = await mapApi.fetchByCatalogId({
        page: pageNumber,
        limit: PAGE_LIMIT,
        catalog_id: catalogId,
      });
      setMaps(maps);
      setMapTotalCount(total_count);
      setIsMapLoading(false);
    } catch (err) {
      logger.error(err);
    }
  };

  const getImages = async pageNumber => {
    try {
      setIsImageLoading(true);
      const {
        data: { library_images, total_count },
      } = await libraryImagesApi.fetch({
        page: pageNumber,
        limit: PAGE_LIMIT,
      });
      setImages(library_images);
      setIsImageLoading(false);
      setLibraryTotalCount(total_count);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (catalogId && isLoggedIn) {
      getMaps(mapPage);
      getImages(libraryPage);
    }
  }, [catalogId]);

  const sideBarLength = useMemo(() => {
    const length = sideBarItems.filter(item => !item.isDeleted).length;
    if (length) setShowAddObjectMenu(false);
    else setShowAddObjectMenu(true);

    return sideBarItems.filter(item => !item.isDeleted).length;
  }, [sideBarItems.length]);

  useEffect(() => {
    if (sideBarItems.filter(item => !item.isDeleted).length) {
      setShowAddObjectMenu(false);
    } else setShowAddObjectMenu(true);
  }, [sideBarItems]);

  const addDesignClick = () => setShowAddObjectMenu(true);

  const handleMapClick = url => onMapsClick(url, () => setActiveTab(1));

  const handleLibraryImageDelete = async id => {
    try {
      setIsMapLoading(true);
      await mapApi.destroy({ ids: [id] });
      getMaps();
    } catch (err) {
      logger.error(err);
    }
  };

  const handleMapsDelete = async id => {
    try {
      setIsImageLoading(true);
      await libraryImagesApi.destroy(id);
      getImages();
    } catch (err) {
      logger.error(err);
    }
  };

  const gotToMaps = (isEdit = false, mapId, details, name, attachmentId) => {
    const bluePrintId = params.get("bluePrintId");
    const canvasHeight = activeVariant.placeholders[0].height;
    const canvasLength = activeVariant.placeholders[0].width;
    const canvasContainer = document.getElementById("canvas-container");
    const srcHeight = canvasContainer?.clientHeight / 1.5;
    const srcWidth = canvasContainer?.clientWidth / 1.5;

    const scaleFactor = Math.min(
      srcHeight / canvasHeight,
      srcWidth / canvasLength
    );

    const height = Math.round(canvasHeight * scaleFactor);
    const width = Math.round(canvasLength * scaleFactor);

    const canvasJson = canvas.toJSON(["id", "imageType"]);

    const payload = {
      canvasObjects: {
        ...canvasJson,
        sideBarItems,
        printOnSide,
      },
      canvasWidth: width,
      activeVariant,
      selectedVariants,
      canvasHeight: height,
    };

    if (bluePrintId) {
      payload.bluePrintId = bluePrintId;
      payload.productId = id;
    } else {
      payload.catalogId = id;
    }

    console.log({ isEdit });

    if (isEdit) {
      const { center, zoom, width, height, bearing } = details;
      mapDispatch({
        type: "SET_MAP_DETAILS",
        payload: {
          center,
          zoom,
          mapId,
          mapName: name,
          canvasWidth: width,
          canvasHeight: height,
          fromProductDesign: true,
          attachmentId,
          bearing,
        },
      });
    } else {
      mapDispatch({
        type: "SET_MAP_DETAILS",
        payload: {
          fromProductDesign: true,
        },
      });
    }

    dispatch({
      type: "SET_PRODUCT_CANVAS_OBJECT",
      payload,
    });

    history.push(`${ADD_MAP_PATH}/${id}`);
  };

  const getContent = option => {
    const productDesignProps = {
      isPrintOnSide,
      allVariants,
      selectedVariants,
      handleVariantSelect,
      handleVarianceClick,
      gotToMaps,
      printOnSide,
      onPrintOnSide,
    };

    const myMapsProps = {
      images: maps,
      onImageClick: handleMapClick,
      isLoading: isMapLoading,
      handleDelete: handleLibraryImageDelete,
      totalCount: mapTotalCount,
      page: mapPage,
      setPage: setMapPage,
      getData: pageNumber => getMaps(pageNumber),
    };

    const myLibProps = {
      isLibrary: true,
      images,
      onImageClick: handleMapClick,
      isLoading: isImageLoading,
      handleDelete: handleMapsDelete,
      page: libraryPage,
      totalCount: libraryTotalCount,
      setPage: setLibraryPage,
      getData: pageNumber => getImages(pageNumber),
    };

    const canvasObjectProps = {
      sideBarItems,
      selectCanvasObject,
      addDesignClick,
      handleObjectPosition,
      setImageSize,
      canvas,
      removeCanvasObject,
      background,
      setBackground,
      editImage: (id, details, name, attachmentId) =>
        gotToMaps(true, id, details, name, attachmentId),
    };

    switch (option) {
      case 1:
        return (
          <>
            <ProductDesignTab {...productDesignProps} />

            <Typography className="mx-3 my-4 text-base font-semibold">
              Step 3 - (Optional)
            </Typography>

            {showAddObjectMenu ? (
              <AddObject
                fileUploadRef={fileUploadRef}
                onAddTextClick={onAddTextClick}
                showCancelButton={!!sideBarLength}
                onCancelClick={() => setShowAddObjectMenu(false)}
              />
            ) : (
              <ListCanvasObject {...canvasObjectProps} />
            )}
          </>
        );
      case 2:
        return <MyImages {...myMapsProps} />;
      case 3:
        return <MyImages {...myLibProps} />;
      default:
        return null;
    }
  };

  const handleVariantSelect = (e, variant) => {
    e.stopPropagation();
    setSelectedVariants([variant]);
    handleVarianceClick(variant);
  };

  return (
    <>
      <div className="sidebar-content flex w-4/12 border-r border-[#E4E5E6]">
        <div className="w-full">
          <HeaderTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isLoggedIn={isLoggedIn}
          />
          <div className="tab-content">
            {isLoading && (imageUploading || imageLoading) ? (
              <div className="flex h-full flex-col items-center justify-center">
                <Spinner />
                <br />
                {imageUploading && <span>Uploading...</span>}
                {imageLoading && <span>Loading...</span>}
              </div>
            ) : (
              <>{getContent(activeTab)}</>
            )}
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={fileUploadRef}
        onChange={onFileChange}
        onClick={event => {
          event.target.value = null;
        }}
        style={{ display: "none" }}
        accept="image/*"
      />
    </>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
