import React from "react";

import { LeftArrow, RightArrow } from "neetoicons";
import { Button } from "neetoui";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <Button
      className="absolute top-1/2 right-2 h-7 w-7 -translate-y-1/2 transform rounded-full bg-gray-200 md:right-2 lg:right-6"
      onClick={onClick}
      icon={() => <RightArrow size="24" color="#000" />}
    />
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <Button
      className="absolute top-1/2 left-2 z-10 h-7 w-7 -translate-y-1/2 transform rounded-full bg-gray-200 md:left-2 lg:left-6"
      onClick={onClick}
      icon={() => <LeftArrow size="24" color="#000" />}
    />
  );
}

export const carouselSettings = count => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: count > 3 ? count : 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "60px",
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1024, // large screens
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: true,
        centerPadding: "20px",
      },
    },
    {
      breakpoint: 768, // medium screens
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "20px",
      },
    },
    {
      breakpoint: 480, // small screens
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "10px",
      },
    },
  ],
});
