export const formatDataFn = item => ({
  label: item.name || item.title,
  value: item.id,
});

export async function convertUrlsToBlobs(urls) {
  const blobs = await Promise.all(
    urls.map(async url => {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch image from ${url}: ${response.statusText}`
        );
      }

      return await response.blob();
    })
  );
  return blobs;
}

export const getActiveIndex = (previewUrls, images, selectedImage) => {
  const combinedList = [
    ...previewUrls,
    ...images.map(image => (typeof image === "string" ? image : image.url)),
  ];

  const activeIndex = combinedList.findIndex(
    item =>
      item ===
      (typeof selectedImage === "string" ? selectedImage : selectedImage.url)
  );

  return activeIndex > 0 ? activeIndex : 0;
};
