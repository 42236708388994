import { useState, useEffect, useRef } from "react";

import { fabric } from "fabric";
import { v4 as uuid } from "uuid";

import mapsApi from "apis/maps";
import { IMAGE, TEXT, LIB, MAP, ROUND_RUG } from "common/constants";
import {
  editImage,
  resizeCanvas,
  layerObjects,
  getBoundary,
  checkAllLoaded,
  addCanvasImage,
  mouseDownHandler,
  checkCenterAlignment,
} from "common/helpers";
import { CUSTOM_FONTS } from "components/ProductDesign/Content/constant";
import { updatePrintOnSideBorder } from "components/ProductDesign/helpers";

const noop = () => {};

const loadCustomFonts = canvas => {
  CUSTOM_FONTS.map(({ value }) => {
    const textObject = new fabric.Text("text", {
      fontFamily: value,
    });
    canvas.add(textObject);
    canvas.renderAll();
    canvas.remove(textObject);
  });
  canvas.renderAll();
};

const useEditor = ({
  scaleStep,
  canvasRef,
  initializer,
  printOnSide,
  setPrintOnSide,
}) => {
  const [canvas, setCanvas] = useState(null);
  const [canvasObjectList, setCanvasObjectList] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [canvasSize, setCanvasSize] = useState({
    height: 0,
    length: 0,
  });
  const [bluePrintId, setBluePrintId] = useState("");
  const [imageToCrop, setImageToCrop] = useState(null);

  const centerLinesRef = useRef({ hLine: null, vLine: null });

  // Open the side bar options when active object changes
  useEffect(() => {
    if (canvas && canvasObjectList.length > 0) {
      canvas.on("mouse:down", evt =>
        mouseDownHandler(evt, canvas, bluePrintId, setSelectedObject)
      );

      canvas.on("object:moving", ({ target }) =>
        checkCenterAlignment(target, canvas, centerLinesRef)
      );
      canvas.on("object:scaling", ({ target }) =>
        checkCenterAlignment(target, canvas, centerLinesRef)
      );
      canvas.on("object:rotating", ({ target }) =>
        checkCenterAlignment(target, canvas, centerLinesRef)
      );

      canvas.on("object:modified", () => {
        centerLinesRef?.current.hLine.set({ visible: false });
        centerLinesRef?.current.vLine.set({ visible: false });
        canvas.renderAll();
      });
    }
  }, [canvas, canvasObjectList]);

  const [loading, setLoading] = useState(false);

  // Add image to the canvas on mapClick
  const addImage = (canvas, image, size, id, isLibrary, printId) => {
    try {
      let attributes = {
        id,
        imageType: LIB,
        type: IMAGE,
        left: canvas.clipPath.left,
        top: canvas.clipPath.top,
      };
      if (!isLibrary) {
        attributes = {
          ...attributes,
          scaleX: (size?.width || canvasSize.length) / image.width,
          scaleY: (size?.height || canvasSize.height) / image.height,
          imageType: MAP,
        };
      }

      image.set(attributes);

      const { index, objects } = layerObjects(
        canvas,
        image,
        bluePrintId || printId
      );

      if (isLibrary) {
        image.scaleToWidth(canvasSize.length);
      }

      let newIndex = index;

      // show the text above the image layer and image layer above the map layer
      if (
        (isLibrary && objects[index]?.imageType === "map") ||
        objects[index]?.type === "text"
      ) {
        newIndex += 1;
      }
      canvas?.insertAt(image, newIndex);
      canvas?.setActiveObject(image);
      canvas?.renderAll();
      return image;
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (canvas) {
      const { hLine, vLine } = resizeCanvas(canvas, canvasSize, bluePrintId);
      centerLinesRef.current = { hLine, vLine };
      if (printOnSide) {
        updatePrintOnSideBorder(canvas, canvasSize.height, canvasSize.length);
      }
    }
  }, [canvasSize]);

  useEffect(() => {
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerSize = 8;
    fabric.Object.NUM_FRACTION_DIGITS = 17;

    const canvas = new fabric.Canvas(canvasRef.current);

    setCanvas(canvas);

    document.getElementById("canvas").fabric = canvas;

    canvas.preserveObjectStacking = true;
    canvas.controlsAboveOverlay = true;
    canvas.backgroundColor = "#e5e7eb";
    canvas.selection = false;
    loadCustomFonts(canvas);

    initializer(canvas, ({ height, width, bluePrintId, printOnSide }) => {
      setBluePrintId(bluePrintId);
      const container = getBoundary(width, height, canvas, bluePrintId);
      canvas.clipPath = container;
      setCanvasSize({ height, length: width });
      setPrintOnSide(printOnSide);
    });

    return () => {
      canvas.dispose();
    };
  }, []);

  const zoomIn = () => {
    const zoom = canvas?.getZoom();
    canvas?.setZoom(zoom / scaleStep);
  };

  const zoomOut = () => {
    const zoom = canvas?.getZoom();
    canvas?.setZoom(zoom * scaleStep);
  };

  const selectCanvasObject = (target, listIndex) => {
    const activeObject = target;
    const cCanvasObjectList = [...canvasObjectList];
    canvas?.setActiveObject(activeObject);
    cCanvasObjectList[listIndex].object = activeObject;
    setCanvasObjectList(cCanvasObjectList);
    setSelectedObject(activeObject);
  };

  const removeCanvasObject = (index, target) => {
    canvas.discardActiveObject();
    const cCanvasObjectList = [...canvasObjectList];
    cCanvasObjectList[index].isDeleted = true;
    setCanvasObjectList(cCanvasObjectList);
    setSelectedObject(null);
    canvas.remove(target);
    canvas.requestRenderAll();
  };

  const setImageSize = (option, value, callBack = noop) => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      switch (option) {
        case "rotate":
          activeObject.rotate(value);
          break;
      }
      callBack(activeObject);
      canvas.renderAll();
    }
  };

  const handleObjectPosition = option => {
    const activeObject = canvas.getActiveObject();
    if (option === "crop") {
      setImageToCrop(activeObject);
    } else if (activeObject) {
      editImage(activeObject, option, canvas, canvasSize);
      canvas.renderAll();
      canvas.setActiveObject(activeObject);
    }
  };

  const getPos = () => {
    if (printOnSide) return 8;

    if (bluePrintId === ROUND_RUG) return 1;

    return 4;
  };

  const handleAddTextClick = () => {
    const cCanvasObjectList = [...canvasObjectList];
    // To identify the canvas objects
    const id = uuid();
    const textObject = new fabric.IText("Tap and Type", {
      id,
      left: canvas.width / 2 - canvasSize.length / 2,
      top: canvas.height / 2 - canvasSize.height / 2,
      type: TEXT,
    });
    // No of line type objects present on the canvas.
    const itemsNumber = getPos();
    canvas.insertAt(textObject, itemsNumber);
    canvas.setActiveObject(textObject);
    setSelectedObject(textObject);

    cCanvasObjectList.push({
      id,
      object: textObject,
      type: TEXT,
      text: "Tap and Type",
    });
    setCanvasObjectList(cCanvasObjectList);
  };

  const updateTextStyles = (option, value) => {
    const activeObject = canvas?.getActiveObject();
    const toggledValue = activeObject[option] === value ? "" : value;
    if (activeObject) {
      // multiple render is used to fix font family issue
      activeObject.set({ [option]: toggledValue });
      canvas.renderAll();
      activeObject.set({ [option]: "Roboto" });
      canvas.renderAll();
      activeObject.set({ [option]: toggledValue });
      activeObject.set("dirty", true);
      canvas.renderAll();
    }
  };

  const onMapsClick = async (
    {
      url: imageUrl,
      name,
      mapId = null,
      mapDetails = null,
      canvasSize = null,
      isLibrary = false,
      showLoader = false,
      libId = null,
      blob = null,
    },
    callBack = () => {},
    canvasRef = canvas,
    SidebarItems = canvasObjectList,
    printId
  ) => {
    let newMapId = null;

    if (!isLibrary || showLoader) setLoading(true);

    // For map image create duplicates to help with the editing feature.
    // A map image can be used for multiple products.
    if (!isLibrary && mapId) {
      if (showLoader) setLoading(true);
      const {
        data: { id },
      } = await mapsApi.createDuplicate(mapId);
      newMapId = id;
    }
    const cCanvasObjectList = [...SidebarItems];
    const imageObject = new Image();
    imageObject.crossOrigin = "Anonymous";
    imageObject.src = imageUrl;
    imageObject.onload = function () {
      const image = new fabric.Image(imageObject);
      const id = uuid();
      const newImage = addImage(
        canvasRef,
        image,
        canvasSize,
        id,
        isLibrary,
        printId
      );
      cCanvasObjectList.push({
        id,
        object: newImage,
        type: IMAGE,
        url: imageUrl,
        mapDetails,
        mapId: newMapId,
        name,
        libId,
        blob,
        isLibrary,
      });
      setCanvasObjectList(cCanvasObjectList);
      setSelectedObject(newImage);
      callBack();
      setLoading(false);
    };
  };

  const renderText = (item, canvasRef, id) => {
    const object = new fabric.IText(item.text, {
      id,
      cornerSize: 8,
      transparentCorners: false,
      top: parseFloat(item.top) || 0,
      left: parseFloat(item.left) || 0,
      fill: item.color,
      type: TEXT,
      layer: item.layer,
      scaleX: item.scale_x,
      scaleY: item.scale_y,
    });

    canvasRef.add(object);
    return object;
  };

  const renderImages = (
    list,
    bluePrintId,
    printOnSide,
    callBack,
    canvasRef = canvas
  ) => {
    const cCanvasObjectList = [...canvasObjectList];

    list.forEach((item, index) => {
      const object = null;
      if (item.attachment_type === IMAGE) {
        addCanvasImage(
          item,
          object,
          cCanvasObjectList,
          list,
          callBack,
          canvasRef,
          bluePrintId,
          printOnSide,
          setCanvasObjectList
        );
      } else {
        const id = uuid();
        const object = renderText(item, canvasRef, id);
        cCanvasObjectList.push({
          id,
          object,
          type: TEXT,
          attachmentId: item.id || null,
          text: item.text,
        });
      }

      if (list.length - 1 === index) {
        setCanvasObjectList(cCanvasObjectList);
        canvasRef?.renderAll();
        checkAllLoaded(
          list.length,
          canvasRef,
          callBack,
          bluePrintId,
          printOnSide
        );
      }
    });
  };

  const renderJson = (canvas, json, callBack) => {
    let objectLength = 0;
    const sideBarItems = json.sideBarItems;
    const idList = sideBarItems.map(el => el.id);
    canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), (o, object) => {
      if (object.type !== "line") {
        const index = idList.indexOf(object.id);
        sideBarItems[index].object = object;
      }
      objectLength += 1;
      if (objectLength === json.objects.length) {
        setCanvasObjectList(sideBarItems);
        callBack(sideBarItems);
      }
    });
  };

  return {
    zoomIn,
    zoomOut,
    canvasObjectList,
    selectCanvasObject,
    handleObjectPosition,
    setImageSize,
    canvas,
    handleAddTextClick,
    selectedObject,
    updateTextStyles,
    onMapsClick,
    removeCanvasObject,
    renderImages,
    renderJson,
    setCanvasSize,
    canvasSize,
    loading,
    imageToCrop,
    setImageToCrop,
  };
};

export default useEditor;
