import axios from "axios";
import qs from "qs";

const PUBLIC_URL = "api/v1/public/subcategories";
const BASE_URL = "api/v1/subcategories";

const fetchByCategoryId = params =>
  axios.get(`${PUBLIC_URL}/get_by_category_id?${qs.stringify(params)}`);

const create = formData =>
  axios.post(BASE_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteSubCategory = id => axios.delete(`${BASE_URL}/${id}`);

const update = (id, formData) =>
  axios.put(`${BASE_URL}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const subcategoriesApi = {
  fetchByCategoryId,
  create,
  update,
  deleteSubCategory,
};

export default subcategoriesApi;
