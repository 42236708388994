import React, { useEffect, useMemo, useState } from "react";

import { Pagination, Spinner, Input } from "neetoui";
import { Header } from "neetoui/layouts";
import { useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";

import catalogueApi from "apis/catalogue";
import categoriesApi from "apis/categories";
import { PAGE_LIMIT } from "common/constants";
import { useProductDispatch } from "contexts/product";
import useDebounce from "hooks/useDebounce";
import NoDataSvg from "images/no-data.svg";
import Search from "images/search.svg";

import ProductCard from "./ProductCard";
import SliderContent from "./SliderContent";

import { carouselSettings } from "../utils";

const breadcrumbs = [{ text: "Catalogs", link: "/catalogs" }];

const Content = () => {
  const [loading, setLoading] = useState(true);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [catalogues, setCatalogues] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category_id = params.get("category");
  const subcategory_id = params.get("subcategory");
  const [categories, setCategories] = useState([]);

  const history = useHistory();
  const productDispatch = useProductDispatch();
  const debouncedSearchTerm = useDebounce(searchTerm);

  const getCategories = async () => {
    const { data } = await categoriesApi.fetch();
    setCategories(data);
    setLoadingCategory(false);
  };

  const fetchCatalogues = async pageNumber => {
    const {
      data: { catalogues, total_count },
    } = await catalogueApi.fetch({
      category_id: params.get("category"),
      subcategory_id: params.get("subcategory"),
      page: pageNumber,
      limit: PAGE_LIMIT,
      search: searchTerm,
    });
    setTotalCount(total_count);
    setCatalogues(catalogues);
    setLoading(false);
  };

  useEffect(() => {
    setLoadingCategory(true);
    getCategories();
  }, []);

  useEffect(() => {
    fetchCatalogues();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    fetchCatalogues(page);
  }, [category_id, subcategory_id]);

  const handleOnClick = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    productDispatch({ type: "INITIALIZE_PRODUCT" });
    history.push(`/product-details/${id}`);
  };

  let breadcrumbs = [{ text: "Catalogs", link: "/catalogs" }];

  if (category_id) {
    breadcrumbs = [{ text: "Categories", link: null }];
  } else if (subcategory_id) {
    breadcrumbs = [
      { text: "Categories", link: "/catalogs" },
      { text: "Subcategories", link: null },
    ];
  }

  const categoriesWithUrl = useMemo(
    () => categories.filter(item => item.url),
    [categories]
  );

  return (
    <div className="relative px-4 sm:px-6 md:px-8 lg:px-12 xl:px-36 ">
      {(loadingCategory || loading) && (
        <div className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform">
          <Spinner />
        </div>
      )}
      {(category_id || subcategory_id) && (
        <Header
          title="Catalogs"
          breadcrumbs={breadcrumbs}
          className="h-auto min-h-0 px-0 pb-3"
        />
      )}
      {!!categories.length && !category_id && !subcategory_id && (
        <div className="sticky top-0 z-20 bg-white">
          <Slider {...carouselSettings(categoriesWithUrl)}>
            {categoriesWithUrl.map(category => (
              <SliderContent category={category} key={category.id} />
            ))}
          </Slider>
        </div>
      )}
      <Input
        size="large"
        className="custom-input m-auto py-4"
        prefix={<Search className="h-6 w-6" />}
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        placeholder="Search for products"
      />
      <div className="content-height grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
        {catalogues.length > 0 ? (
          catalogues.map(
            ({
              id,
              title,
              print_providers: { variants },
              min_price,
              catalog_images = [],
            }) => (
              <ProductCard
                key={id}
                title={title}
                size={variants.length}
                minPrice={min_price?.toFixed(2)}
                image={
                  catalog_images.filter(image => image.is_active)[0]?.url || ""
                }
                handleOnClick={e => handleOnClick(e, id)}
              />
            )
          )
        ) : (
          <>
            {!loading && !loadingCategory && (
              <div className="col-span-1 flex h-full flex-col items-center justify-center sm:col-span-2 md:col-span-3 lg:col-span-4">
                <div className="w-1/3">
                  <NoDataSvg />
                </div>
                <br />
                <div>No Data</div>
              </div>
            )}
          </>
        )}
      </div>

      <Pagination
        className="pagination-component my-5"
        count={totalCount}
        navigate={value => {
          setPage(value);
          setLoading(true);
          fetchCatalogues(value);
        }}
        pageNo={page}
        pageSize={PAGE_LIMIT}
      />
    </div>
  );
};

export default Content;
