import React, { useEffect, useState, useRef } from "react";

import { Typography } from "@bigbinary/neetoui";

const ShowList = ({ value, title }) => {
  const [maxWidth, setMaxWidth] = useState(0);
  const itemRefs = useRef([]);

  useEffect(() => {
    const widths = itemRefs.current.map(ref => (ref ? ref.offsetWidth : 0));
    const max = Math.max(...widths);
    setMaxWidth(max);
  }, [value]);

  return (
    <div className="mb-4 w-full md:w-4/5">
      <Typography variant="h3" className="mb-4">
        {title}
      </Typography>
      <div className="px-4 md:px-10">
        <div className="flex flex-wrap gap-4">
          {value.map((item, index) => (
            <div
              key={index}
              ref={el => (itemRefs.current[index] = el)}
              className="flex items-center justify-center bg-gray-100 p-1 px-2 text-center text-sm text-gray-500"
              style={{ minWidth: maxWidth }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShowList;
