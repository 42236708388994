import React, { useState, useEffect, useRef } from "react";

import { Modal } from "@bigbinary/neetoui";
import { Button, Toastr } from "neetoui";
import { useLocation, useHistory } from "react-router-dom";
import { isPresent } from "utils";

import productApi from "apis/printify/product";
import {
  LOGIN_PATH,
  MY_DRAFT_PATH,
  PRODUCT_CART_PATH,
} from "components/routeConstants";
import { useAuthState } from "contexts/auth";
import { useMapDispatch } from "contexts/map";
import { useProductDispatch } from "contexts/product";
import { useProductState } from "contexts/product";
import { useUserState } from "contexts/user";
import useEditor from "hooks/useEditor";
import useProduct from "hooks/useProduct";

import Content from "./Content";
import CropImage from "./CropImage";
import Header from "./Header";
import { showPrintOnSides } from "./helpers";
import LoadingIndicator from "./LoadingIndicator";
import PreviewProduct from "./PreviewProduct";
import Sidebar from "./Sidebar";

const ProductDesign = () => {
  const [printOnSide, setPrintOnSide] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaveToCart, setIsSaveToCart] = useState(false);
  const [product, setProduct] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [error, setError] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const hiddenFileInput = useRef(null);
  const canvasRef = useRef(null);
  const history = useHistory();
  const dispatch = useMapDispatch();
  const productDispatch = useProductDispatch();
  const { authToken } = useAuthState();
  const { user } = useUserState();

  const isLoggedIn = isPresent(authToken) && isPresent(user);

  const { printOnSide: isPrintOnSide } = useProductState();

  const initializer = (canvas, callBack) => {
    const bluePrintId = params.get("bluePrintId");
    if (bluePrintId) {
      getProduct(bluePrintId, canvas, callBack);
      setIsDraft(true);
    } else {
      catalogueDetails(canvas, callBack);
    }
  };

  const {
    canvasObjectList,
    selectCanvasObject,
    handleObjectPosition,
    setImageSize,
    canvas,
    handleAddTextClick,
    selectedObject,
    updateTextStyles,
    onMapsClick,
    removeCanvasObject,
    renderImages,
    renderJson,
    setCanvasSize,
    loading: imageLoading,
    canvasSize,
    imageToCrop,
    setImageToCrop,
  } = useEditor({
    scaleStep: 0.5,
    canvasRef,
    initializer,
    printOnSide,
    setPrintOnSide,
  });

  const {
    isApiLoading,
    selectedVariants,
    activeVariant,
    catalogueData,
    isMapImageLoading,
    productName,
    imageId,
    background,
    catalogueDetails,
    getProduct,
    setActiveVariant,
    setProductName,
    setBackground,
    setSelectedVariants,
    createProduct,
  } = useProduct({
    renderJson,
    onMapsClick,
    renderImages,
    printOnSide,
    canvasObjectList,
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const removePreviewProducts = async () => await productApi.destroyPreview();

  useEffect(() => {
    setPrintOnSide(isPrintOnSide);
  }, [isPrintOnSide]);

  useEffect(
    () => () => {
      if (isLoggedIn) {
        // remove the preview product if created.
        removePreviewProducts();
        productDispatch({
          type: "REMOVE_SAVED_PRODUCT",
        });
      }
      dispatch({ type: "REMOVE_ID" });
    },
    []
  );

  const onPrintOnSide = e => {
    setPrintOnSide(e.target.checked);
  };

  useEffect(() => {
    if (canvas) {
      canvas.backgroundColor = `#${background}`;
      canvas.renderAll();
    }
  }, [background]);

  useEffect(() => {
    if (canvas) {
      const objects = canvas.getObjects();
      const { length: width, height } = canvasSize;
      if (printOnSide && height > 0) {
        showPrintOnSides({ canvas, height, width });
      } else {
        for (let i = 0; i < objects.length; i++) {
          if (objects[i].id === "print-on-side") {
            canvas.remove(objects[i]);
          }
        }
      }
      canvas?.renderAll();
    }
  }, [printOnSide]);

  const productCallback = product => {
    setIsPreviewLoading(false);
    if (product) {
      setProduct(product);
      setShowPreview(true);
    }
  };

  const getPreview = async (isCreate = false) => {
    try {
      if (!productName && isCreate) {
        Toastr.error(Error("Product name cannot be empty."));
        setError(true);
      } else {
        setIsPreviewLoading(true);
        if (!isCreate) setIsPreview(true);
        isDraft && isCreate
          ? createProduct({
              status: 2,
              isEdit: true,
              imageId,
              callBack: productCallback,
            })
          : createProduct({
              status: 0,
              callBack: productCallback,
            });
      }
    } catch (err) {
      logger.error(err);
    }
  };

  const saveProduct = async saveToCart => {
    try {
      if (saveToCart) setIsSaveToCart(true);
      setLoading(true);
      await productApi.updateStatus({
        product_id: product?.id,
        status: !saveToCart ? 2 : 1,
      });
      setLoading(false);
      history.push(saveToCart ? PRODUCT_CART_PATH : MY_DRAFT_PATH);
    } catch (err) {
      setLoading(false);
      logger.error(err);
    }
  };

  const handleVarianceClick = variant => {
    setActiveVariant(variant);
    const canvasHeight = variant.placeholders[0].height;
    const canvasLength = variant.placeholders[0].width;
    const canvasContainer = document.getElementById("canvas-container");

    const srcHeight = canvasContainer?.clientHeight / 1.5;
    const srcWidth = canvasContainer?.clientWidth / 1.5;

    const scaleFactor = Math.min(
      srcHeight / canvasHeight,
      srcWidth / canvasLength
    );

    const height = Math.round(canvasHeight * scaleFactor);
    const width = Math.round(canvasLength * scaleFactor);

    setCanvasSize({ height, length: width });
  };

  const handleProductNameChange = value => {
    setProductName(value);
    setError(false);
  };

  const redirectToLogin = () => {
    const canvasJson = canvas?.toJSON(["id", "imageType"]);
    const payload = {
      canvasObjects: {
        ...canvasJson,
        sideBarItems: canvasObjectList,
        printOnSide,
      },
      hasSavedProduct: true,
      locationPath: location.pathname,
    };
    productDispatch({
      type: "SET_PRODUCT_CANVAS_OBJECT",
      payload,
    });
    dispatch({
      type: "REMOVE_MAP_IMAGES",
    });
    history.push(LOGIN_PATH, { from: location.pathname });
  };

  return (
    <div
      className="product-design"
      style={{ overflow: "auto", minWidth: "915px" }}
    >
      <LoadingIndicator
        isApiLoading={isApiLoading}
        isPreviewLoading={isPreviewLoading}
        isMapImageLoading={isMapImageLoading}
      />

      <Header
        handlePreview={() => getPreview()}
        catalogueData={catalogueData}
        productName={productName}
        handleProductNameChange={handleProductNameChange}
        error={error}
        isLoggedIn={isLoggedIn}
      />
      <div className="product-design-body flex">
        <Sidebar
          isLoggedIn={isLoggedIn}
          fileUploadRef={hiddenFileInput}
          onAddTextClick={handleAddTextClick}
          sideBarItems={canvasObjectList}
          selectCanvasObject={selectCanvasObject}
          handleObjectPosition={handleObjectPosition}
          setImageSize={setImageSize}
          canvas={canvas}
          onMapsClick={onMapsClick}
          removeCanvasObject={removeCanvasObject}
          background={background}
          setBackground={setBackground}
          selectedVariants={selectedVariants}
          handleVarianceClick={handleVarianceClick}
          activeVariant={activeVariant}
          isLoading={!(isApiLoading || isMapImageLoading)}
          imageUploading={imageUploading}
          imageLoading={imageLoading}
          allVariants={catalogueData?.print_providers.variants}
          setSelectedVariants={setSelectedVariants}
          catalogId={catalogueData?.id}
          setImageUploading={setImageUploading}
          isPrintOnSide={catalogueData?.print_on_side}
          onPrintOnSide={onPrintOnSide}
          printOnSide={printOnSide}
        />
        <Content
          canvasEl={canvasRef}
          selectedObject={selectedObject}
          updateTextStyles={updateTextStyles}
          isLoading={isApiLoading || isMapImageLoading}
          bluePrintId={catalogueData?.blue_print_id}
        />
      </div>
      <div className="product-design-footer flex h-12">
        <div className="flex w-4/12 items-center justify-center justify-center border-t border-r border-[#E4E5E6] bg-white py-2">
          <Button
            label="Save and continue"
            className="rounded-full px-6"
            disabled={!canvasObjectList.length || loading}
            onClick={() => {
              isLoggedIn ? getPreview(true) : redirectToLogin();
            }}
          />
        </div>
        <div className="justify flex w-8/12 border-t border-[#E4E5E6] bg-white p-2 "></div>
      </div>
      <PreviewProduct
        isPreview={isPreview}
        isOpen={showPreview}
        onClose={() => {
          setShowPreview(false);
          setIsPreview(false);
          setProduct(null);
        }}
        productName={productName}
        disabled={!canvasObjectList.length || loading}
        onAddToCart={() => saveProduct(true)}
        isAddingToCart={loading && isSaveToCart}
        onSaveAsDraft={() => saveProduct()}
        isSavingToCart={loading && !isSaveToCart}
        images={product?.images || []}
      />
      <Modal
        isOpen={!!imageToCrop}
        size="large"
        closeButton={false}
        onClose={() => {
          setImageToCrop(null);
        }}
      >
        <CropImage
          imageObject={imageToCrop}
          ogCanvas={canvas}
          onClose={() => {
            setImageToCrop(null);
          }}
        />
      </Modal>
    </div>
  );
};

ProductDesign.propTypes = {};

export default ProductDesign;
