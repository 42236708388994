import React, { useEffect, useState } from "react";

import { Crisp } from "crisp-sdk-web";
import { Spinner } from "neetoui";
import PropTypes from "prop-types";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { isPresent } from "utils";

import { setAuthHeaders, registerIntercepts } from "apis/axios";
import { initializeLogger } from "common/logger";
import { isMobileDevice } from "common/utils";
import MobileView from "components/Common/MobileView";
import PrivateRoute from "components/Common/PrivateRoute";
import {
  AUTH_ROUTES,
  CATEGORIES_PATH,
  PRIVATE_ROUTES,
} from "components/routeConstants";
import { useAuthState, useAuthDispatch } from "contexts/auth";
import { useUserDispatch, useUserState } from "contexts/user";
import {
  clearLocalStorageCredentials,
  getFromLocalStorage,
} from "utils/storage";

const Main = props => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { authToken } = useAuthState();
  const { user: userContextState } = useUserState();
  const userDispatch = useUserDispatch();
  const authDispatch = useAuthDispatch();
  const currentUser = userContextState || props?.user;
  const isLoggedIn = isPresent(authToken) && isPresent(currentUser);
  useEffect(() => {
    userDispatch({ type: "SET_USER", payload: { user: props?.user } });
    initializeLogger();
    registerIntercepts(authDispatch);
    setAuthHeaders(setLoading);
  }, [authDispatch, props?.user, userDispatch]);

  useEffect(() => {
    const isMobileBrowser = isMobileDevice();
    if (isMobileBrowser) {
      setIsOpen(true);
    }
    Crisp.configure(process.env.CRISP_WEBSITE_ID);
  }, []);

  useEffect(() => {
    const previousLoginAuthEmail = getFromLocalStorage("authEmail");
    const hasDeviseUserSessionExpired = !props?.user;
    const sessionExpiredButLocalStorageCredsExist =
      hasDeviseUserSessionExpired && previousLoginAuthEmail;
    if (sessionExpiredButLocalStorageCredsExist) clearLocalStorageCredentials();
  }, [props?.user?.email]);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Switch>
          {AUTH_ROUTES.map(route => (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={route.component}
            />
          ))}
          {PRIVATE_ROUTES.map(route => (
            <PrivateRoute
              key={route.path}
              path={route.path}
              redirectRoute={route.redirectRoute || CATEGORIES_PATH}
              condition={isLoggedIn}
              component={route.component}
            />
          ))}
        </Switch>
      </BrowserRouter>
      <MobileView isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

Main.propTypes = {
  user: PropTypes.object,
};

export default Main;
