import React, { useEffect, useState } from "react";

import { Filter } from "@bigbinary/neeto-icons";
import { Pagination, Button, Spinner, Modal, Alert, Input } from "neetoui";

import catalogueApi from "apis/catalogue";
import { PAGE_LIMIT } from "common/constants";
import Filters from "components/Admin/Catalogs/Filter";
import useDebounce from "hooks/useDebounce";
import NoDataSvg from "images/no-data.svg";
import Search from "images/search.svg";

import ProductCard from "./ProductCard";

import AddCatalog from "../AddCatalog";

const Catalogue = () => {
  const [loading, setLoading] = useState(true);
  const [catalogues, setCatalogues] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [catalogId, setCatalogId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm);

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const getCatalogues = async (pageNumber, filter = {}) => {
    const {
      data: { catalogues, total_count },
    } = await catalogueApi.fetch({
      page: pageNumber,
      limit: PAGE_LIMIT,
      search: searchTerm,
      category_id: filter.category,
      subcategory_id: filter.subcategory,
    });
    setTotalCount(total_count);
    setCatalogues(catalogues);
    setLoading(false);
    setShowFilter(false);
  };

  useEffect(() => {
    getCatalogues(page);
  }, [debouncedSearchTerm]);

  const handleCreteCatalog = () => setIsOpen(true);

  const deleteCatalog = async id => {
    try {
      setIsDeleting(true);
      await catalogueApi.deleteCatalog(id);
    } catch (error) {
      logger.error(error);
    } finally {
      getCatalogues(page);
      setIsDeleting(false);
      setDeleteId(null);
    }
  };

  const handleClose = () => {
    setCatalogId(null);
    setIsOpen(false);
  };

  const applyFilter = filter => {
    getCatalogues(page, filter);
  };

  return (
    <>
      <div className="catalogue relative w-full px-10 py-5">
        {isOpen && (
          <Modal isOpen={isOpen} size="small" onClose={handleClose}>
            <AddCatalog
              id={catalogId}
              callBack={() => getCatalogues(page)}
              handleClose={handleClose}
            />
          </Modal>
        )}
        {loading && (
          <div className="absolute top-1/2 left-1/2 z-10">
            <Spinner />
          </div>
        )}
        <div className="mb-2 flex items-center justify-between">
          <Input
            size="large"
            className="custom-input m-auto py-4"
            prefix={<Search className="h-6 w-6" />}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search for products"
          />
          <div className="ml-10 flex justify-center gap-2">
            <Button icon={Filter} onClick={() => setShowFilter(true)} />
            <Button label="Add catalog" onClick={handleCreteCatalog} />
          </div>
        </div>

        <div className="content-height grid grid-cols-3 gap-4">
          {catalogues.length > 0 ? (
            catalogues.map(
              ({
                id,
                title,
                print_providers: { variants },
                min_price,
                catalog_images = [],
              }) => (
                <ProductCard
                  key={id}
                  title={title}
                  size={variants.length}
                  minPrice={min_price?.toFixed(2)}
                  images={catalog_images
                    .filter(image => image.is_active)
                    .map(image => image.url || "")}
                  isAdmin
                  deleteCatalog={() => setDeleteId(id)}
                  editClick={() => {
                    setCatalogId(id);
                    setIsOpen(true);
                  }}
                />
              )
            )
          ) : (
            <>
              {!loading && (
                <div className="col-span-1 flex h-full flex-col items-center justify-center sm:col-span-2 md:col-span-3 lg:col-span-4">
                  <div className="w-1/3">
                    <NoDataSvg />
                  </div>
                  <br />
                  <div>No Products</div>
                </div>
              )}
            </>
          )}
        </div>
        <Pagination
          className="pagination-component my-5"
          count={totalCount}
          navigate={value => {
            setPage(value);
            setLoading(true);
            getCatalogues(value);
          }}
          pageNo={page}
          pageSize={PAGE_LIMIT}
        />
        <Alert
          isOpen={!!deleteId}
          title="Delete Catalog"
          message="Are you sure?"
          onClose={() => {
            setDeleteId(null);
          }}
          onSubmit={() => deleteCatalog(deleteId)}
          isSubmitting={isDeleting}
        />
      </div>
      <Filters
        showPane={showFilter}
        onClose={handleFilterClose}
        applyFilter={applyFilter}
      />
    </>
  );
};

export default Catalogue;
