import React from "react";

import { useHistory } from "react-router-dom";

const SliderContent = ({ category }) => {
  const history = useHistory();
  return (
    <div
      key={category.id}
      className="flex h-52 w-full cursor-pointer flex-col items-center p-2"
      onClick={() => history.push(`subcategories/${category.id}`)}
    >
      <div className="flex h-full w-full items-center justify-center overflow-hidden">
        <img
          src={category.url}
          alt={category.name}
          className="max-w-32 h-auto max-h-32 w-auto object-contain" // Adjusted size
        />
      </div>
      <div className="text-center text-sm font-bold">{category.name}</div>
    </div>
  );
};

export default SliderContent;
