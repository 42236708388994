import React from "react";

import { Button } from "neetoui";
import { Input } from "neetoui/formik";

const BluePrintSearch = ({
  handleInputChange,
  setFieldValue,
  searchTerm,
  bluePrints,
  handleOptionClick,
  resetForm,
  loadingBluePrint,
  fetchCatalogDetails,
  bluePrintId,
}) => (
  <div className="grid grid-cols-2 gap-4">
    <div className="relative">
      <Input
        multiple
        label="Blue print "
        name="bluePrint"
        placeholder="Enter blue print title"
        onChange={e => handleInputChange(e, setFieldValue)}
      />
      {!!(searchTerm && bluePrints.length) && (
        <ul className="absolute z-10 max-h-96 w-full overflow-y-scroll border border-gray-300 bg-white">
          {bluePrints.map(({ title, blue_print_id }) => (
            <li
              className="cursor-pointer p-2 hover:bg-gray-200"
              key={blue_print_id}
              onClick={() =>
                handleOptionClick(
                  title,
                  blue_print_id,
                  setFieldValue,
                  resetForm
                )
              }
            >
              {title}
            </li>
          ))}
        </ul>
      )}
    </div>
    <div className="grid grid-cols-3 gap-4">
      <Input
        className="col-span-2"
        label="Blue print ID"
        name="bluePrintId"
        placeholder="Enter blue print ID"
      />
      <div className="flex items-end">
        <Button
          label="Search"
          onClick={() =>
            fetchCatalogDetails({
              bluePrintId,
              resetForm,
            })
          }
          loading={loadingBluePrint}
        />
      </div>
    </div>
  </div>
);

export default BluePrintSearch;
