import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { INITIAL_FIELD_DETAILS, INITIAL_MAP_DETAILS } from "common/constants";
import mapReducer from "reducers/mapReducer";

const MapStateContext = React.createContext();
const MapDispatchContext = React.createContext();
const LOCAL_STORAGE_KEY = "map-state-01";

const INITIAL_STATE = {
  map: null,
  draw: null,
  layers: { plss: false, clu: false, naip: false, clss: false, naip2: false },
  additionalLayers: {
    showRoads: false,
    showLabels: false,
    adminBoundaries: true,
  },
  backgroundMap: "Satellite",
  options: {
    showCanvasEdges: true,
  },
  fieldDetails: INITIAL_FIELD_DETAILS,
  showFieldModal: false,
  uploadedMapId: null,
  mapDetails: INITIAL_MAP_DETAILS,
};

const MapProvider = ({ children }) => {
  const localState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
  const [state, dispatch] = React.useReducer(mapReducer, {
    ...INITIAL_STATE,
    ...localState,
  });

  useEffect(() => {
    if (state) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          additionalLayers: state.additionalLayers,
          uploadedMapId: state.uploadedMapId,
          mapDetails: state.mapDetails,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.additionalLayers,
    state.layers,
    state.uploadedMapId,
    state.mapDetails,
  ]);
  return (
    <MapStateContext.Provider value={state}>
      <MapDispatchContext.Provider value={dispatch}>
        {children}
      </MapDispatchContext.Provider>
    </MapStateContext.Provider>
  );
};

const useMapState = () => {
  const context = React.useContext(MapStateContext);
  if (context === undefined) {
    throw new Error("useMapState must be used within a MapProvider");
  }

  return context;
};

const useMapDispatch = () => {
  const context = React.useContext(MapDispatchContext);
  if (context === undefined) {
    throw new Error("useMapDispatch must be used within a MapProvider");
  }

  return context;
};

const useMap = () => [useMapState(), useMapDispatch()];

MapProvider.propTypes = {
  children: PropTypes.node,
};

export { MapProvider, useMapState, useMapDispatch, useMap };
