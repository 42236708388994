import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { Modal, Typography } from "neetoui";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";

import { VALIDATION_SCHEMA } from "./validation";

const CreateEdit = ({
  isOpen,
  onClose,
  onSubmit,
  category,
  showSubCategory = false,
}) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    if (category?.url) {
      setPreviewUrl(category?.url);
    }
  }, [category]);

  const convertFileToBlob = file => {
    const reader = new FileReader();

    reader.onload = () => {
      const arrayBuffer = reader.result;
      const blob = new Blob([arrayBuffer], { type: file.type });
      setFile(blob);
    };

    reader.readAsArrayBuffer(file);
  };
  const onFileChange = e => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        convertFileToBlob(selectedFile);
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setFile(null);
        setPreviewUrl(null);
      }}
      closeButton={false}
      size="medium"
      className="land-category-picker rounded"
    >
      <Modal.Header>
        {showSubCategory ? (
          <Typography>Add Subcategory</Typography>
        ) : (
          <Typography>{category ? "Edit" : "Create"} Category</Typography>
        )}
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ name: category?.name || "" }}
          onSubmit={values => {
            onSubmit(values, file);
            setFile(null);
            setPreviewUrl(null);
          }}
          validationSchema={VALIDATION_SCHEMA}
          validateOnChange
          validateOnBlur
        >
          {({ isSubmitting }) => (
            <Form className="grid grid-cols-2 gap-2 pb-5">
              <Input name="name" label="Name" className="col-span-2" />
              <div className="col-span-2">
                {previewUrl && (
                  <div
                    className="relative mb-3"
                    style={{
                      minHeight: 250,
                      minWidth: 250,
                      background: "#fafafa",
                    }}
                  >
                    <img src={previewUrl} />
                  </div>
                )}
                <input type="file" onChange={onFileChange} accept="image/*" />
              </div>
              <div className="s col-span-2 mt-1 flex items-center justify-center">
                <Button
                  label="Cancel"
                  style="secondary"
                  className="mr-2 rounded-full"
                  onClick={onClose}
                />
                <Button
                  type="submit"
                  label="Save"
                  className="rounded-full"
                  loading={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateEdit;
