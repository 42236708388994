import React from "react";

import { Dropdown } from "neetoui";

import {
  MY_MAPS_PATH,
  MY_ORDERS_PATH,
  MY_DRAFT_PATH,
  PERSONAL_DETAILS,
  GALLERY,
} from "components/routeConstants";
import DownSvg from "images/dropdown-down.svg";
import UserSvg from "images/user-icon.svg";

const { Menu, MenuItem } = Dropdown;

const UserMenu = ({ is_admin, handleLogout, handleAdminClick }) => (
  <Dropdown
    className="border-none shadow-2xl"
    label={<UserSvg />}
    buttonProps={{
      className: "p-2 shadow-none rounded-none bg-none",
      style: "link",
    }}
    icon={DownSvg}
  >
    <Menu>
      <MenuItem.Button to={MY_MAPS_PATH}>My maps</MenuItem.Button>
      <MenuItem.Button to={MY_ORDERS_PATH}>My Orders</MenuItem.Button>
      <MenuItem.Button to={MY_DRAFT_PATH}>Draft products</MenuItem.Button>
      <MenuItem.Button to={PERSONAL_DETAILS}>Personal details</MenuItem.Button>
      <MenuItem.Button to={GALLERY}>My Library</MenuItem.Button>
      {is_admin && (
        <MenuItem.Button onClick={handleAdminClick}>
          Admin Panel
        </MenuItem.Button>
      )}
      <MenuItem.Button onClick={handleLogout}>Logout</MenuItem.Button>
    </Menu>
  </Dropdown>
);

export default UserMenu;
