import React from "react";

import dayjs from "dayjs";
import { Button } from "neetoui";

import { MY_ORDERS_PATH } from "components/routeConstants";

import Row from "./Row";

export const makeColumns = onEdit => [
  {
    title: "Orders",
    dataIndex: "map",
    key: "map",
    render: (_, row) => (
      <div className="flex items-center">
        <img src={row.line_items[0]?.product.images[0]?.src} className="h-32" />
      </div>
    ),
  },
  {
    title: "details",
    dataIndex: "details",
    key: "details",
    render: (_, row) => (
      <div className="grid grid-cols-2 gap-1 px-4">
        <Row title="Order ID:" value={row.printify_order_id} />
        <Row
          title="Placed order:"
          value={dayjs(row.updated_at).format("YYYY.MM.DD")}
        />
        <Row title="Ordered items:" value={row.line_items.length} />
        <Row title="Status:" value={row.status} />
      </div>
    ),
  },
  {
    title: "action",
    dataIndex: "action",
    key: "action",
    align: "center",
    render: (_, row) => (
      <>
        {row.status !== "pending" ? (
          <Button
            style="secondary"
            label="View details"
            className="rounded-full"
            to={`${MY_ORDERS_PATH}/${row.id}`}
          />
        ) : (
          <Button
            label="Proceed to payment"
            className="rounded-full"
            onClick={() => onEdit(row.id)}
          />
        )}
      </>
    ),
  },
];
