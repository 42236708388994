import React from "react";

import { Typography, Button } from "@bigbinary/neetoui";

import { LAYERS } from "common/constants";
import { useMapState, useMapDispatch } from "contexts/map";

import CustomSwitch from "./CustomSwitch";

const LayersList = () => {
  const {
    layers: { naip, naip2, plss, clu },
    map,
  } = useMapState();
  const mapDispatch = useMapDispatch();

  const onHandleLayersChange = (checked, type) => {
    const value = checked ? "visible" : "none";
    const toggle = !!checked;
    const layers = {
      plss,
      clu,
      naip: false,
      naip2: false,
    };

    switch (type) {
      case "naip":
        layers.naip = toggle;
        map.setLayoutProperty("naip-layer", "visibility", value);
        break;
      case "naip2":
        layers.naip2 = toggle;
        map.setLayoutProperty("naip2-layer", "visibility", value);
        break;
    }
    Object.keys(layers).forEach(key => {
      LAYERS[key]?.forEach(lyr =>
        map.setLayoutProperty(
          lyr,
          "visibility",
          layers[key] ? "visible" : "none"
        )
      );
    });
    mapDispatch({
      type: "SET_LAYERS",
      payload: layers,
    });
  };

  const handleBaseClick = () => {
    const layers = {
      plss,
      clu,
      naip: false,
      naip2: false,
    };
    map.setLayoutProperty("naip-layer", "visibility", "none");
    map.setLayoutProperty("naip2-layer", "visibility", "none");

    mapDispatch({
      type: "SET_LAYERS",
      payload: layers,
    });
  };

  return (
    <>
      <div className="title mb-3 mt-2">Base map layers</div>
      <div className="m-3">
        <CustomSwitch
          checked={!naip && !naip2}
          label="Base map"
          onChange={event => handleBaseClick(event.target.checked)}
          tooltipContent="Imagery worldwide with very high resolution in certain urban areas"
        />
        <CustomSwitch
          checked={naip}
          label="2017-2018 Imagery"
          onChange={event => onHandleLayersChange(event.target.checked, "naip")}
          tooltipContent="USDA layer US only"
        />
        <CustomSwitch
          checked={naip2}
          label="2022-2023 Imagery"
          onChange={event =>
            onHandleLayersChange(event.target.checked, "naip2")
          }
          tooltipContent="Most updated - USDA layer US only"
        />
        <Typography className="py-1 text-xs font-normal text-gray-400">
          Questions? Contact us{" "}
          <Button
            style="link"
            href="https://boundri.com/contact-us/"
            label="here"
            target="_blank"
          />{" "}
          or click the chat button in the lower right corner
        </Typography>
        <Typography className="py-1 text-xs font-normal text-gray-400">
          Selectable imagery only available in the US at this time
        </Typography>
      </div>
    </>
  );
};

export default LayersList;
