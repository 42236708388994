import React from "react";

import LayersDropDown from "./LayersDropDown";

const ManageFields = () => (
  <div className="relative gap-4">
    <LayersDropDown />
  </div>
);
ManageFields.propTypes = {};

export default ManageFields;
