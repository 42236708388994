import React from "react";

const CartButton = ({ onClick }) => (
  <a className="header-cart" onClick={onClick}>
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.331 21H6.67a2.997 2.997 0 01-2.951-2.463L2.017 9.18A1 1 0 013 8.001h8.28l1.772-5.317a1.002 1.002 0 011.265-.635 1 1 0 01.631 1.268l-2 6A.999.999 0 0112 10H4.198l1.487 8.179a1 1 0 00.984.82h10.662a1 1 0 00.985-.82L19.802 10H16a1 1 0 010-2h5a1 1 0 01.983 1.18l-1.7 9.357A2.997 2.997 0 0117.33 21z"
        fill="#E4E5E6"
      ></path>
    </svg>
  </a>
);

export default CartButton;
