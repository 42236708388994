import React, { useEffect, useState } from "react";

import { Pane, Typography, Button, Select } from "neetoui";

import categoriesApi from "apis/categories";

const Filter = ({ showPane, onClose, applyFilter }) => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [category, setCategory] = useState();
  const [subCategory, setSubcategory] = useState();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const { data } = await categoriesApi.fetch();
    setCategories(
      data.map(item => ({
        label: item.name,
        value: item.id,
        subcategories: item.subcategories,
      }))
    );
  };

  const handleCategoryChange = category => {
    setCategory(category.value);
    setSubcategories(
      category.subcategories.map(item => ({
        label: item.name,
        value: item.id,
      }))
    );
  };

  const handleSubcategoryChange = subCategory => {
    setSubcategory(subCategory.value);
  };

  return (
    <Pane isOpen={showPane} onClose={onClose}>
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          Filter Catalog
        </Typography>
      </Pane.Header>
      <Pane.Body>
        <div className="grid w-full grid-cols-1 gap-4">
          <Select
            label="Category"
            placeholder="Select"
            options={categories}
            onChange={handleCategoryChange}
          />
          <Select
            label="Subcategory"
            placeholder="Select"
            options={subcategories}
            isDisabled={!category}
            onChange={handleSubcategoryChange}
          />
        </div>
      </Pane.Body>
      <Pane.Footer className="flex items-center space-x-2">
        <Button
          label="Filter"
          onClick={() => applyFilter({ category, subCategory })}
        />
      </Pane.Footer>
    </Pane>
  );
};

export default Filter;
