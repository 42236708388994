import React, { useEffect, useState, useMemo } from "react";

import { Spinner } from "neetoui";
import { useParams, useHistory } from "react-router-dom";

import cataloguesApi from "apis/catalogue";
import Header from "components/Common/Header";

import Carousel from "./Carousel";
import ColorList from "./ColorList";
import Details from "./Details";
import ShowList from "./ShowList";

const cleanArray = array => [
  ...new Set(array.filter(item => item !== undefined)),
];

const ProductDetails = () => {
  const [productDetails, setProductDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  const getProductDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await cataloguesApi.fetchById(id);
      setProductDetails(data);
    } catch (err) {
      logger.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  const details = useMemo(() => {
    const sizes = cleanArray(
      productDetails?.print_providers?.variants.map(
        variant => variant.options.size
      ) || []
    );
    const depths = cleanArray(
      productDetails?.print_providers?.variants.map(
        variant => variant.options.depth
      ) || []
    );
    const colors = cleanArray(
      productDetails?.print_providers?.variants.map(
        variant => variant.options.color
      ) || []
    );

    return { sizes, depths, colors };
  }, [productDetails]);

  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    history.push(`/product-design/${id}`);
  };

  return (
    <div className="product-details h-screen w-full">
      <Header />
      {isLoading ? (
        <div className="flex h-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="my-14 grid w-full grid-cols-1 gap-4 sm:grid-cols-1 sm:px-6 md:my-8 md:grid-cols-2 md:px-6 lg:px-12 xl:px-36">
          <div className="sm:px-4 md:pl-6 lg:pl-12 xl:pl-32">
            <Carousel
              images={productDetails?.catalog_images?.map(item => item.url)}
            />
          </div>
          <div className="sm:px-4 md:pl-2 lg:pr-12 xl:pr-32">
            <Details
              title={productDetails?.title}
              description={productDetails?.description}
              handleClick={handleClick}
              price={productDetails?.min_price}
            />
            {!!details.colors.length && <ColorList value={details.colors} />}
            {!!details.sizes.length && (
              <ShowList value={details.sizes} title="Sizes" />
            )}
            {!!details.depths.length && (
              <ShowList value={details.depths} title="Depths" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
