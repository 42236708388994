const borderBuffer = {
  width: 2,
  height: 1.7,
  top: 0.5,
  left: 0,
};

const FONTS = [
  { label: "Times New Roman", value: "Times New Roman" },
  { label: "Arial", value: "Arial" },
  { label: "Verdana", value: "Verdana" },
  { label: "Trebuchet MS", value: "Trebuchet MS" },
  { label: "Tahoma", value: "Tahoma" },
  { label: "Georgia", value: "Georgia" },
  { label: "Garamond", value: "Garamond" },
  { label: "Courier New", value: "Courier New" },
  { label: "Brush Script MT", value: "Brush Script MT" },
];

const CUSTOM_FONTS = [
  { label: "Roboto", value: "roboto" },
  { label: "Sans", value: "Sans" },
  { label: "Droid", value: "Droid" },
  { label: "Helvetica", value: "Helvetica" },
  { label: "Carter One", value: "Carter One" },
  { label: "Caveat", value: "Caveat" },
  { label: "Dancing Script", value: "Dancing Script" },
  { label: "Gaegu", value: "Gaegu" },
  { label: "Monsieur La Doulaise", value: "Monsieur La Doulaise" },
  { label: "Patrick Hand", value: "Patrick Hand" },
  { label: "Protest Revolution", value: "Protest Revolution" },
  { label: "Roboto", value: "Roboto" },
  { label: "Sixtyfour", value: "Sixtyfour" },
];

export { borderBuffer, FONTS, CUSTOM_FONTS };
