import React from "react";

import TickSvg from "images/blackTick.svg";
import DeleteSvg from "images/delete-icon.svg";

const Previews = ({
  setSelectedImage,
  url,
  idx,
  removePreviewUrls,
  isSelected,
}) => (
  <div
    className="relative cursor-pointer"
    style={{
      minHeight: 250,
      minWidth: 250,
      background: "#fafafa",
    }}
    onClick={() => {
      setSelectedImage(url);
    }}
  >
    <div
      className="absolute right-1 top-1 z-10 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
      onClick={() => removePreviewUrls(url, idx)}
    >
      <DeleteSvg size={20} />
    </div>
    {isSelected && <TickSvg className="absolute left-1 top-1 cursor-pointer" />}
    <img src={url} alt={`Preview ${idx}`} />
  </div>
);

export default Previews;
