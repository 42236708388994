import React from "react";

import { Button } from "neetoui";
import { useHistory } from "react-router-dom";
import { isPresent } from "utils";

import authenticationApi from "apis/authentication";
import {
  CATALOGS,
  LOGIN_PATH,
  PRODUCT_CART_PATH,
} from "components/routeConstants";
import { useAuthState, useAuthDispatch } from "contexts/auth";
import { useUserState } from "contexts/user";
import { getFromLocalStorage } from "utils/storage";

import CartButton from "./CartButton";
import Logo from "./Logo";
import MainMenu from "./MainMenu";
import UserMenu from "./UserMenu";

const Header = () => {
  const history = useHistory();
  const authDispatch = useAuthDispatch();
  const { authToken } = useAuthState();
  const { user: userContextState } = useUserState();
  const currentUser = userContextState;
  const isLoggedIn = isPresent(authToken) && isPresent(currentUser);
  const is_admin = getFromLocalStorage("is_admin");

  const handleLogout = async () => {
    try {
      await authenticationApi.logout();
      authDispatch({ type: "LOGOUT" });
      window.location.href = LOGIN_PATH;
    } catch (error) {
      console.error(error);
    }
  };

  const handleCartClick = () => history.push(PRODUCT_CART_PATH);
  const handleAdminClick = () => history.push(CATALOGS);

  return (
    <div className="common">
      <header>
        <div className="container flex">
          <Logo />
          <MainMenu />
          <div className="h-btns w-1/3">
            <div className="mob-menu-toggle">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <div className="flex items-center justify-end">
              <CartButton onClick={handleCartClick} />
              {isLoggedIn ? (
                <UserMenu
                  is_admin={is_admin}
                  handleLogout={handleLogout}
                  handleAdminClick={handleAdminClick}
                />
              ) : (
                <Button
                  label="Sign in"
                  to={LOGIN_PATH}
                  className="rounded-full"
                />
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
