import React, { useEffect, useState } from "react";

import { Form, Formik, FieldArray } from "formik";
import { Button, Spinner } from "neetoui";
import { Input, Textarea, Select } from "neetoui/formik";

import catalogueApi from "apis/catalogue";
import categoriesApi from "apis/categories";
import subcategoriesApi from "apis/subcategoriesApi";
import useDebounce from "hooks/useDebounce";

import BluePrintSearch from "./BluePrintSearch";
import { INITIAL_VALUES } from "./constants";
import ExistingImages from "./ExistingImages";
import Previews from "./Previews";
import { formatDataFn } from "./utils";
import { convertUrlsToBlobs, getActiveIndex } from "./utils";
import { VALIDATION_SCHEMA } from "./validation";
import VariantList from "./VariantList";

const AddCatalog = ({ id, callBack, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [printProviders, setPrintProviders] = useState([]);
  const [variants, setVariants] = useState([]);
  const [loadingBluePrint, setLoadingBluePrint] = useState(false);
  const [loadingSubcategory, setLoadingSubcategory] = useState(false);
  const [loadingVariants, setLoadingVariants] = useState(false);
  const [catalogLoaded, setCatalogLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [bluePrints, setBluePrints] = useState([]);
  const [files, setFiles] = useState([]);
  const [previewUrl, setPreviewUrl] = useState([]);
  const [bluePrintId, setBluePrintId] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [isCategoryLoading, setCategoryLoading] = useState(false);
  const [deleteImageIds, setDeleteImageIds] = useState([]);
  const [removedItems, setRemovedItems] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm);

  const fetchCategories = async () => {
    const { data } = await categoriesApi.fetch();
    const formatData = data.map(formatDataFn);
    setCategories(formatData);
  };

  const fetchBluePrints = async () => {
    const {
      data: { blue_prints },
    } = await catalogueApi.searchBluePrints({
      search: searchTerm,
    });
    setBluePrints(blue_prints);
  };

  const fetchVariants = async (printProviderId, id = null) => {
    const {
      data: { variants },
    } = await catalogueApi.fetchVariants({
      id: printProviderId,
      bluePrintId: bluePrintId || id,
    });

    const formatData = variants.map(item => ({
      label: item.title,
      value: item.id,
      variant: item,
    }));

    setVariants(formatData);
  };
  const fetchCatalogDetailsById = async () => {
    try {
      const { data } = await catalogueApi.fetchById(id);
      if (data) {
        const {
          data: { providers },
        } = await catalogueApi.fetchPrintProviders({
          bluePrintId: data.blue_print_id,
        });
        setBluePrintId(data.blue_print_id);
        setPrintProviders(providers.map(formatDataFn));
        fetchVariants(data.print_providers.id, data.blue_print_id);
        data.category?.id && fetchSubCategories(data.category?.id || "");

        setInitialValues({
          ...INITIAL_VALUES,
          ...data,
          bluePrint: data.title,
          bluePrintId: data.blue_print_id,
          printProvider: {
            value: data.print_providers.id,
            label: data.print_providers.title,
          },
          category: {
            label: data.category?.name || "",
            value: data.category?.id || "",
          },
          subcategory: {
            label: data.subcategory?.name || "",
            value: data.subcategory?.id || "",
          },
          variants: data.print_providers.variants.map(variant => ({
            variant: { label: variant.title, value: variant.id, variant },
            price: variant.price,
          })),
          images:
            data.catalog_images.length === 0
              ? data.images
              : data.catalog_images,
        });
      }

      if (data.catalog_images.length === 0) {
        const images = await convertUrlsToBlobs(data.images);
        setFiles(images);
        setSelectedImage(data.images[0]);
      } else {
        setSelectedImage(
          data.catalog_images.find(image => image.is_active === true) ||
            data.catalog_images[0]
        );
      }
      setCatalogLoaded(true);
      setLoading(false);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchBluePrints();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchCatalogDetailsById();
    }
    fetchCategories();
  }, []);

  const fetchCatalogDetails = async ({ bluePrintId, resetForm }) => {
    setLoadingBluePrint(true);
    const {
      data: { details },
    } = await catalogueApi.fetchCatalogDetails({ bluePrintId });

    const {
      data: { providers },
    } = await catalogueApi.fetchPrintProviders({ bluePrintId });

    setPrintProviders(providers.map(formatDataFn));

    const images = await convertUrlsToBlobs(details.images);
    setFiles(images);

    resetForm({
      values: {
        ...initialValues,
        bluePrint: details.title,
        bluePrintId: details.id,
        ...details,
      },
    });
    setSelectedImage(details.images[0]);
    setBluePrintId(details.id);
    setCatalogLoaded(true);
    setLoadingBluePrint(false);
  };

  const fetchSubCategories = async id => {
    try {
      setLoadingSubcategory(true);
      const { data } = await subcategoriesApi.fetchByCategoryId({
        id,
      });
      const formatData = data.map(formatDataFn);
      setSubcategories(formatData);
    } catch (err) {
      logger.error(err);
    } finally {
      setLoadingSubcategory(false);
    }
  };

  const handleCategoryChange = async (value, setValue) => {
    setValue("category", value);
    setValue("subcategory", null);
    fetchSubCategories(value.value);
  };

  const handleProviderChange = async (value, setValue, bluePrintId) => {
    setLoadingVariants(true);
    setValue("printProvider", value);
    setValue("variants", [{ variant: null, price: null }]);
    fetchVariants(value.value, bluePrintId);
    setLoadingVariants(false);
  };

  const formatValues = values => {
    let minPrice = values.variants[0].price;
    let printOnSides = false;
    const variants = values.variants.map(item => {
      printOnSides = item.variant.variant.options.depth ?? false;
      const isUpdated = !item.isNewlyCreated
        ? !!(
            initialValues?.variants?.find(
              variant => variant?.variant?.value === item?.variant?.value
            )?.price != item.price
          )
        : false;
      minPrice = item.price < minPrice ? item.price : minPrice;
      return {
        ...item.variant.variant,
        price: item.price,
        isUpdated,
        isNewlyCreated: !!item.isNewlyCreated,
      };
    });

    return {
      blue_print_id: bluePrintId,
      title: values.title,
      description: values.description,
      model: values.model,
      brand: values.brand,
      images: [
        selectedImage,
        ...values.images.filter(item => item !== selectedImage),
      ],
      print_providers: {
        id: values.printProvider.value,
        title: values.printProvider.label,
        variants,
      },
      subcategory_id: values.subcategory.value,
      category_id: values.category.value,
      min_price: minPrice,
      print_on_side: printOnSides,
    };
  };

  const convertFileToBlob = file => {
    const reader = new FileReader();

    reader.onload = () => {
      const arrayBuffer = reader.result;
      const blob = new Blob([arrayBuffer], { type: file.type });
      setFiles(files => [...files, blob]);
    };

    reader.readAsArrayBuffer(file);
  };

  const onFileChange = async e => {
    const selectedFiles = Array.from(e.target.files); // Get the selected files

    const previewUrls = selectedFiles.map(file => URL.createObjectURL(file)); // Create URLs for previews
    setPreviewUrl(previews => [...previews, ...previewUrls]);

    selectedFiles.map(file => convertFileToBlob(file));
  };

  const handleSubmit = async values => {
    try {
      const formattedValues = formatValues(values);
      const formData = new FormData();
      formData.append("catalog_data", JSON.stringify(formattedValues));

      const activeIndex = getActiveIndex(
        previewUrl,
        values.images,
        selectedImage
      );

      formData.append("active_index", activeIndex);
      if (removedItems.length) {
        formData.append("deleted_variants", JSON.stringify(removedItems));
      }

      if (files?.length > 0) {
        files?.forEach(file => formData.append("catalog_images[]", file));
      }

      if (deleteImageIds.length > 0) {
        formData.append("delete_image_ids", JSON.stringify(deleteImageIds));
      }

      if (id) {
        await catalogueApi.editCatalog(id, formData);
      } else {
        await catalogueApi.createCatalog(formData);
      }
      callBack();
      handleClose();
    } catch (err) {
      logger.error(err);
    }
  };

  const handleInputChange = async (e, setFieldValue) => {
    setFieldValue("bluePrint", e.target.value);
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (
    title,
    blue_print_id,
    setFieldValue,
    resetForm
  ) => {
    setFieldValue("bluePrint", title);
    setFieldValue("blue_print_id", blue_print_id);
    setBluePrints([]);
    fetchCatalogDetails({
      bluePrintId: blue_print_id,
      resetForm,
    });
  };

  const addAllVariant = (allVariant, setFieldValue) => {
    const list = variants
      .filter(
        variant =>
          !allVariant.find(
            item => item?.variant?.value === variant?.variant?.id
          )
      )
      .map(variant => ({
        variant,
        price: null,
      }));

    setFieldValue(
      "variants",
      [...allVariant, ...list].filter(item => item.variant)
    );
  };

  const deleteImage = (image, images, setFieldValue, idx) => {
    const imageUrl = typeof image === "string" ? image : image.url;

    setFieldValue(
      "images",
      images.filter(
        item => (typeof item === "string" ? item : item.url) !== imageUrl
      )
    );

    if (image.id) {
      setDeleteImageIds(ids => [...ids, image.id]);
    } else {
      setFiles(files => files.filter((_, index) => index !== idx));
    }
  };

  const handleCreateCategory = async (inputValue, setFieldValue) => {
    try {
      setCategoryLoading(true);
      const formData = new FormData();
      formData.append("data", JSON.stringify({ name: inputValue }));
      const {
        data: { categories, category },
      } = await categoriesApi.create(formData);
      setCategories(categories.map(formatDataFn));
      setFieldValue("category", formatDataFn(category));
      setFieldValue("subcategory", "");
    } finally {
      setCategoryLoading(false);
    }
  };

  const handleCreateSubCategory = async (
    inputValue,
    setFieldValue,
    categoryId
  ) => {
    setLoadingSubcategory(true);
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify({ name: inputValue }));
      formData.append("category_id", categoryId);

      const {
        data: { subcategories, subcategory },
      } = await subcategoriesApi.create(formData);
      setSubcategories(subcategories.map(formatDataFn));
      setFieldValue("subcategory", formatDataFn(subcategory));
    } finally {
      setLoadingSubcategory(false);
    }
  };

  const removePreviewUrls = (url, idx) => {
    setPreviewUrl(previewUrl => previewUrl.filter(preview => preview !== url));
    setFiles(files => files.filter((_, index) => index !== idx));
  };

  const handleRemoveVariant = value => {
    if (value.variant.variant.product_id) {
      setRemovedItems(variants => [...variants, value.variant.variant]);
    }
  };

  return (
    <>
      {(loading || loadingBluePrint) && (
        <div className="absolute top-1/2 left-1/2 z-10">
          <Spinner />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ resetForm, values, setFieldValue, isSubmitting }) => (
          <Form className="w-full space-y-2 bg-white p-6">
            <BluePrintSearch
              handleInputChange={handleInputChange}
              setFieldValue={setFieldValue}
              searchTerm={searchTerm}
              bluePrints={bluePrints}
              handleOptionClick={handleOptionClick}
              resetForm={resetForm}
              loadingBluePrint={loadingBluePrint}
              fetchCatalogDetails={fetchCatalogDetails}
              bluePrintId={values.bluePrintId}
            />
            <div className="grid grid-cols-2 gap-4">
              <Input
                required
                label="Title"
                name="title"
                placeholder="Enter title"
                disabled={!catalogLoaded}
              />
              <Input
                label="Brand"
                name="brand"
                placeholder="Enter brand"
                disabled={!catalogLoaded}
              />
              <Input
                label="Model"
                name="model"
                placeholder="Enter model"
                disabled={!catalogLoaded}
              />
              <Select
                isCreateable
                required
                label="Category"
                name="category"
                options={categories}
                onChange={value => handleCategoryChange(value, setFieldValue)}
                placeholder="Select category"
                isLoading={isCategoryLoading}
                isDisabled={!catalogLoaded}
                onCreateOption={value =>
                  handleCreateCategory(value, setFieldValue)
                }
              />
              <Select
                isCreateable
                required
                label="Subcategory"
                name="subcategory"
                options={subcategories}
                isLoading={loadingSubcategory || isCategoryLoading}
                placeholder="Select subcategory"
                isDisabled={!catalogLoaded || !values.category}
                helpText={
                  catalogLoaded &&
                  !values.category &&
                  "please select a category"
                }
                onCreateOption={value =>
                  handleCreateSubCategory(
                    value,
                    setFieldValue,
                    values?.category?.value
                  )
                }
              />
              <Select
                required
                className="col-start-1"
                label="Print provider"
                name="printProvider"
                options={printProviders}
                onChange={value =>
                  handleProviderChange(value, setFieldValue, bluePrintId)
                }
                placeholder="Select print provider"
                isDisabled={!catalogLoaded}
              />
              <FieldArray name="variants">
                {({ push, remove }) => (
                  <VariantList
                    variants={values.variants}
                    variantList={variants}
                    loadingVariants={loadingVariants}
                    isDisabled={!catalogLoaded || !values.printProvider}
                    showHelpText={catalogLoaded && !values.printProvider}
                    handleRemoveVariant={handleRemoveVariant}
                    push={push}
                    remove={remove}
                    addAllVariant={addAllVariant}
                    setFieldValue={setFieldValue}
                  />
                )}
              </FieldArray>
              <Textarea
                required
                className="col-span-2"
                label="Description"
                name="description"
                disabled={!catalogLoaded}
              />
              <div className="space-y-6">
                <input
                  type="file"
                  onChange={onFileChange}
                  disabled={!catalogLoaded}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              {previewUrl.map((url, idx) => (
                <Previews
                  key={idx}
                  setSelectedImage={setSelectedImage}
                  url={url}
                  idx={idx}
                  removePreviewUrls={removePreviewUrls}
                  isSelected={selectedImage === url}
                />
              ))}
              {values?.images?.map((image, idx) => {
                const imageUrl = typeof image === "string" ? image : image.url;
                const isSelected =
                  (selectedImage?.url || selectedImage) === imageUrl;

                return (
                  <ExistingImages
                    key={idx}
                    setSelectedImage={setSelectedImage}
                    image={image}
                    deleteImage={image =>
                      deleteImage(image, values?.images, setFieldValue, idx)
                    }
                    showDelete={values?.images.length > 1}
                    isSelected={isSelected}
                    imageUrl={imageUrl}
                  />
                );
              })}
            </div>
            <div className="flex justify-center">
              <Button type="submit" label="Submit" loading={isSubmitting} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCatalog;
