import React, { useState } from "react";

import { Button } from "@bigbinary/neetoui";
import PropTypes from "prop-types";

import dummyImage from "images/catalogue-image.jpg";
// Add a loader icon or spinner

const ProductCard = ({ title, minPrice, size, image, handleOnClick }) => {
  const [loading, setLoading] = useState(true); // State for loading

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once the image is loaded
  };

  const handleImageError = () => {
    setLoading(false); // In case of error, stop loading
  };

  return (
    <div onClick={handleOnClick} className="flex flex-col items-center">
      <div
        className="relative flex w-full items-center justify-center"
        style={{ height: "auto", background: "#fafafa" }}
      >
        {loading && (
          <div className="flex h-60 w-full items-center justify-center">
            <img
              src={dummyImage}
              alt="Loading..."
              className="object-contain" // Adjust size to match your spinner
            />
          </div>
        )}
        <img
          src={image || dummyImage}
          alt="Content"
          className={`max-h-60 max-w-full object-contain ${
            loading ? "hidden" : ""
          }`}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
      </div>
      <div className="w-full p-4">
        <div className="mb-1 text-xs font-normal text-gray-600">{title}</div>
        {minPrice && size && (
          <div className="gap-4">
            <div>
              <div className="mb-1 text-sm">
                From <strong>USD {minPrice}</strong>
              </div>
              <div className="text-xs font-normal text-gray-400">
                {size} {size > 1 ? "sizes" : "size"}
              </div>
            </div>
            <div className="mt-2 flex items-center justify-end">
              <Button label="Build it now" className="rounded-full" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number,
  minPrice: PropTypes.number,
  handleOnClick: PropTypes.func,
};

export default ProductCard;
