import React from "react";

import { Typography, Tooltip } from "@bigbinary/neetoui";

const toCamelCase = text =>
  text
    .toLowerCase()
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word;
      }

      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");

const ColorList = ({ value }) => (
  <div className="mb-4 w-4/5">
    <Typography variant="h3" className="mb-4">
      Colors
    </Typography>
    <div className="flex flex-wrap px-10">
      {value.map((item, index) => (
        <Tooltip
          key={index}
          content={item}
          followCursor="horizontal"
          position="top"
        >
          <div
            className={`bg-${toCamelCase(
              item
            )} m-2 h-5 w-5 rounded-full border border-gray-200`}
          ></div>
        </Tooltip>
      ))}
    </div>
  </div>
);

export default ColorList;
