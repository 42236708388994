import React from "react";

import { Modal, Typography } from "neetoui";

const MobileView = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} size="small" onClose={onClose} className="mobile-view">
    <Modal.Header>
      <Typography id="dialog1Title" className="text-xl sm:text-2xl md:text-3xl">
        Warning!!
      </Typography>
    </Modal.Header>
    <Modal.Body className="py-2 sm:py-3 md:py-4">
      <Typography className="text-xs leading-normal sm:text-sm md:text-base">
        Product builder is not suited for mobile applications beyond certain
        tablets. Select continue to proceed.
      </Typography>
    </Modal.Body>
  </Modal>
);

export default MobileView;
