import React from "react";

import { Typography, Spinner } from "neetoui";

const LoadingIndicator = ({
  isApiLoading,
  isPreviewLoading,
  isMapImageLoading,
}) => (
  <>
    {(isApiLoading || isPreviewLoading || isMapImageLoading) && (
      <div className="fixed z-50 flex h-screen w-full items-center justify-center bg-gray-200 bg-opacity-50">
        {(isApiLoading || isMapImageLoading) && <Spinner />}

        {isPreviewLoading && (
          <div className="flex w-1/4 flex-col items-center rounded bg-white px-5 py-10">
            <Spinner />
            <div className="mt-5">Uploading information...</div>
            <Typography className="py-2 text-center text-xs font-normal text-gray-400">
              This could take up to 30 seconds to render the image to the
              product.
            </Typography>
          </div>
        )}
      </div>
    )}
  </>
);

export default LoadingIndicator;
