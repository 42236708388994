import React, { useEffect, useState } from "react";

import { Toastr, Spinner, Typography } from "neetoui";
import { useHistory, useParams } from "react-router-dom";
import { isPresent } from "utils";

import mapApi from "apis/maps";
import { CATEGORIES_PATH, MY_MAPS_PATH } from "components/routeConstants";
import { useAuthState } from "contexts/auth";
import { useMapDispatch, useMapState } from "contexts/map";
import { useProductState, useProductDispatch } from "contexts/product";
import { useUserState } from "contexts/user";
import useMaps from "hooks/useMaps";
import useProduct from "hooks/useProduct";

import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const AddMap = () => {
  const [angle, setAngle] = useState(0);
  const [mapName, setMapName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(false);
  const [size, setSize] = useState();
  const [progress, setProgress] = useState("");

  const history = useHistory();
  const { id } = useParams();
  const productDispatch = useProductDispatch();
  const {
    bluePrintId,
    canvasObjects,
    canvasHeight,
    activeVariant,
    canvasWidth,
  } = useProductState();
  const { authToken } = useAuthState();
  const { user } = useUserState();

  const isLoggedIn = isPresent(authToken) && isPresent(user);

  const {
    mapDetails: {
      center,
      zoom,
      mapId,
      mapName: existingMapName,
      fromProductDesign,
      attachmentId,
    },
  } = useMapState();

  const dispatch = useMapDispatch();
  const { isApiLoading, catalogueDetails, getProduct, catalogueData } =
    useProduct({});

  useEffect(() => {
    if (existingMapName) setMapName(existingMapName);
  }, [existingMapName]);

  useEffect(() => {
    if (!activeVariant) history.push(CATEGORIES_PATH);

    if (catalogueData) {
      const { height: edgeHeight, width: edgeLength } =
        activeVariant.placeholders[0];
      setSize({
        label: activeVariant.title.split("/")[0],
        value: {
          width: canvasWidth,
          height: canvasHeight,
          originalWidth: edgeLength,
          originalHeight: edgeHeight,
        },
      });
    }
  }, [catalogueData]);

  useEffect(() => {
    initializer();
    return () => {
      dispatch({ type: "REMOVE_MAP_DETAILS" });
    };
  }, []);

  const initializer = () => {
    if (bluePrintId) {
      getProduct(bluePrintId);
    } else {
      catalogueDetails();
    }
  };

  const { mapContainer, map: mainMap, generateImage } = useMaps(center, zoom);

  const handleMapNameChange = value => {
    setMapName(value);
    setError(false);
  };

  const handleMapUpload = async ({ formData, newDetails }) => {
    if (attachmentId) formData.append("attachment_id", attachmentId);
    const {
      data: { map, name },
    } = await mapApi.update(mapId, formData);

    const updatedCanvasObjects = { ...canvasObjects };
    if (updatedCanvasObjects && Object.keys(updatedCanvasObjects).length) {
      let objectId = "";

      updatedCanvasObjects.sideBarItems.map(item => {
        if (item.mapId === mapId && !item.isDeleted) {
          objectId = item.id;
          item.url = map;
          item.name = name;
          item.mapDetails = newDetails;
        }

        return item;
      });

      updatedCanvasObjects.objects.map(item => {
        if (item.id === objectId) {
          item.src = map;
        }

        return item;
      });

      productDispatch({
        type: "UPDATE_CANVAS_OBJECTS",
        payload: updatedCanvasObjects,
      });
    } else {
      history.push(MY_MAPS_PATH);
    }

    history.push(handleRedirect());
    setIsUploading(false);
  };

  const createMap = async ({ formData }) => {
    const {
      data: { id: newMapId },
    } = await mapApi.create(formData);

    dispatch({ type: "SET_MAP_ID", payload: newMapId });
    history.push(handleRedirect());
    setIsUploading(false);
  };

  const generateStaticImage = async () => {
    try {
      if (!mapName) {
        Toastr.error("Map name cannot be empty.");
        setError(true);
        return;
      }

      setIsUploading(true);
      const details = {
        height: size?.value?.height,
        width: size?.value?.width,
        catalogueId: catalogueData?.id,
        originalWidth: size?.value?.originalWidth,
        originalHeight: size?.value?.originalHeight,
      };

      generateImage(
        details,
        mapName,
        setProgress,
        (formData, newDetails, { blob, name }) => {
          setProgress("Uploading image...");
          if (isLoggedIn) {
            if (mapId) {
              handleMapUpload({ formData, newDetails });
            } else {
              createMap({ formData });
            }
          } else {
            dispatch({
              type: "SET_MAP_URL",
              payload: { blob, name, details: newDetails },
            });
            history.push(handleRedirect());
            setIsUploading(false);
          }
        }
      );
    } catch (err) {
      Toastr.error("An error occurred while generating the map.");
    }
  };

  const handleRedirect = () => {
    let path = !fromProductDesign ? MY_MAPS_PATH : `/product-design/${id}`;
    if (bluePrintId) path = `${path}?bluePrintId=${bluePrintId}`;

    return path;
  };

  return (
    <div className="map-design" style={{ overflow: "auto", minWidth: 915 }}>
      {(isApiLoading || isUploading) && (
        <div className="fixed z-50 flex h-screen w-full items-center justify-center bg-gray-200 bg-opacity-50">
          {isUploading && (
            <div className="flex w-11/12 flex-col items-center rounded bg-white px-5 py-10 md:w-1/2 lg:w-1/4">
              <Spinner />
              <div className="mt-5">{progress}</div>
              <Typography className="py-2 text-center text-xs font-normal text-gray-400">
                This could take up to 1 minute depending on the area of interest
                and your internet connection.
              </Typography>
            </div>
          )}
          {isApiLoading && <Spinner />}
        </div>
      )}

      <Header
        handleMapNameChange={handleMapNameChange}
        mapName={mapName}
        error={error}
        catalogueData={catalogueData}
      />
      <div className="add-map-body flex">
        <Sidebar className="w-full md:w-1/4 lg:w-1/5" />
        <Content
          className="w-full md:w-3/4 lg:w-4/5"
          mapRef={mapContainer}
          map={mainMap}
          angle={angle}
          setAngle={setAngle}
          size={size}
        />
      </div>
      <Footer
        printMap={generateStaticImage}
        handleRedirect={handleRedirect}
        className="mt-auto w-full"
      />
    </div>
  );
};

export default AddMap;
