import React, { useState } from "react";

import { Table, Button, Alert } from "neetoui";

import subcategoriesApi from "apis/subcategoriesApi";

import CreateEdit from "./CreateEdit";
import { columnData } from "./utils";

const SubcategoriesTable = ({ subcategories, getInitialData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteSubcategory = async () => {
    try {
      setIsDeleting(true);
      await subcategoriesApi.deleteSubCategory(deleteId);
      setDeleteId(null);
      getInitialData();
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = async (values, file) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(values));
    if (file) formData.append("file", file);
    await subcategoriesApi.update(subcategory?.id, formData);
    setSubcategory(null);
    setIsOpen(false);
    getInitialData();
  };

  return (
    <div
      className="relative m-auto bg-white"
      style={{
        height: subcategories.length * 50,
        width: "calc(100vw - 300px)",
        minHeight: 150,
      }}
    >
      <div className="absolute right-0 top-0">
        <Button label="Add new subcategory" />
      </div>
      <Table
        isLoading={false}
        columnData={columnData({
          setDeleteId,
          setCategory: setSubcategory,
          setIsOpen,
          name: "Subcategory",
        })}
        rowData={subcategories}
        defaultPageSize={100}
        currentPageNumber={1}
        fixedHeight
        rowSelection={false}
      />
      <Alert
        isOpen={!!deleteId}
        title="Delete subcategory"
        message="Are you sure?"
        onClose={() => setDeleteId(null)}
        onSubmit={deleteSubcategory}
        isSubmitting={isDeleting}
      />
      <CreateEdit
        isOpen={isOpen}
        onClose={() => {
          setSubcategory(null);
          setIsOpen(false);
        }}
        onSubmit={handleSubmit}
        category={subcategory}
      />
    </div>
  );
};

export default SubcategoriesTable;
