import React from "react";

import { Button } from "neetoui";
import PropTypes from "prop-types";

import { CATEGORIES_PATH } from "components/routeConstants";
import ArrowSvg from "images/arrow-back.svg";

import SideHeader from "./SideHeader";

const Header = ({
  handlePreview,
  catalogueData,
  handleProductNameChange,
  productName,
  error,
  isLoggedIn,
}) => (
  <div className="product-design-header flex w-full border border-[#E4E5E6]">
    <SideHeader
      productName={productName}
      handleProductNameChange={handleProductNameChange}
      error={error}
    />

    <div className="flex w-8/12 justify-between px-4 py-4">
      <div className="flex items-center">
        <Button
          icon={ArrowSvg}
          iconPosition="left"
          className="px-2.5 text-xs font-normal text-gray-500"
          style="link"
          to={CATEGORIES_PATH}
          label="Back to categories"
        />
        <div className="px-2.5">
          <div className="text-sm font-bold text-black">
            {catalogueData?.title}
          </div>
          <div className="text-xs font-normal text-gray-400">
            {catalogueData?.brand}
          </div>
        </div>
      </div>
      <Button
        label="Preview"
        onClick={handlePreview}
        className="rounded-full px-6"
        disabled={!isLoggedIn}
      />
    </div>
  </div>
);

Header.propTypes = {
  catalogueData: PropTypes.object,
};

export default Header;
