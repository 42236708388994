import React, { useEffect, useState } from "react";

import { Header as HeaderWithCrumbs } from "neetoui/layouts";
import { useHistory } from "react-router-dom";

import categoriesApi from "apis/categories";
import Footer from "components/Common/Footer";
import Header from "components/Common/Header";
import NoData from "components/Common/NoData";

import ProductCard from "../Catalogue/Content/ProductCard";

const CategoryList = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const getCategories = async () => {
    setLoading(true);
    const { data } = await categoriesApi.fetch();

    setSubcategories(data);
    setLoading(false);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleOnClick = (e, id) => history.push(`subcategories/${id}`);

  return (
    <div className="h-screen w-full">
      <Header />
      <div className="m-auto">
        <div className="px-20">
          <HeaderWithCrumbs title="Categories" />
          <div className="content-height grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
            {subcategories.length > 0 ? (
              subcategories.map(({ id, name, url }) => (
                <ProductCard
                  key={id}
                  title={name}
                  image={url}
                  handleOnClick={e => handleOnClick(e, id)}
                />
              ))
            ) : (
              <>{!loading && <NoData />}</>
            )}
          </div>{" "}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CategoryList;
