import React, { useState } from "react";

import { Button } from "neetoui";

import LoadFields from "./LoadFields";
import ManageFields from "./ManageFields";
// import Count from "./MapCount";

const Sidebar = () => {
  const [activeTab] = useState(1);

  return (
    <div className="sidebar-content flex w-4/12 border-r border-[#E4E5E6] px-2 lg:w-3/12">
      <div className="w-full">
        <div className="tab-content map-design flex flex-col justify-between bg-white">
          {activeTab === 1 && <ManageFields />}
          {activeTab === 2 && <LoadFields />}
        </div>
        <div className="flex justify-center pb-3">
          <Button
            className="cursor-pointer"
            label="Click here to request other imagery"
            style="secondary"
            href="https://boundri.com/contact-us/"
          />
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
