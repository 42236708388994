import React, { useEffect, useState } from "react";

import { Header as HeaderWithCrumbs } from "neetoui/layouts";
import { useHistory, useParams } from "react-router-dom";

import subcategoriesApi from "apis/subcategoriesApi";
import Footer from "components/Common/Footer";
import Header from "components/Common/Header";
import NoData from "components/Common/NoData";
import { CATALOGUE_PATH } from "components/routeConstants";

import ProductCard from "../Catalogue/Content/ProductCard";

const SubcategoryList = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id: category_id } = useParams();

  const getSubcategories = async () => {
    setLoading(true);
    const { data } = await subcategoriesApi.fetchByCategoryId({
      id: category_id,
    });
    setSubcategories(data);
    setLoading(false);
  };

  useEffect(() => {
    getSubcategories();
  }, []);

  const handleOnClick = (e, id) => {
    history.push(`${CATALOGUE_PATH}?subcategory=${id}`);
  };

  return (
    <div className="h-screen w-full">
      <Header />
      <div className="m-auto">
        <div className="px-20">
          <HeaderWithCrumbs
            title="Subcategories"
            breadcrumbs={[{ text: "Categories", link: "/categories" }]}
          />
          <div className="content-height grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
            {subcategories.length > 0 ? (
              subcategories.map(({ id, name, url }) => (
                <ProductCard
                  key={id}
                  title={name}
                  image={url}
                  handleOnClick={e => handleOnClick(e, id)}
                />
              ))
            ) : (
              <>{!loading && <NoData />}</>
            )}
          </div>{" "}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SubcategoryList;
