import React, { useState } from "react";

import classnames from "classnames";
import { Select } from "neetoui";

import BoldSvg from "images/bold.svg";
import ItalicSvg from "images/italic.svg";
import TextAlignCenter from "images/text-align-center.svg";
import TextAlignLeft from "images/text-align-left.svg";
import TextAlignRight from "images/text-align-right.svg";
import UnderlineSvg from "images/underline.svg";

import { FONTS, CUSTOM_FONTS } from "./constant";

import ColorPicker from "../ColorPicker";

const ALL_FONTS = [...FONTS, ...CUSTOM_FONTS];

const ToolBar = ({ showToolbar, updateTextStyles }) => {
  const [color, setColor] = useState("000");

  return (
    <div
      className={classnames("text-toolbar z-10 flex", {
        block: showToolbar,
        hidden: !showToolbar,
      })}
    >
      <Select
        className="text-family"
        options={ALL_FONTS}
        defaultValue={ALL_FONTS[0]}
        onChange={option => updateTextStyles("fontFamily", option.value)}
      />
      <div className="text-style">
        <div
          className="cursor-pointer"
          onClick={() => updateTextStyles("fontWeight", "bold")}
        >
          <BoldSvg />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => updateTextStyles("fontStyle", "italic")}
        >
          <ItalicSvg />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => updateTextStyles("underline", true)}
        >
          <UnderlineSvg />
        </div>
      </div>
      <div className="text-position">
        <div
          className="cursor-pointer"
          onClick={() => updateTextStyles("textAlign", "left")}
        >
          <TextAlignLeft />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => updateTextStyles("textAlign", "center")}
        >
          <TextAlignCenter />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => updateTextStyles("textAlign", "right")}
        >
          <TextAlignRight />
        </div>
      </div>
      <div className="text-color">
        <ColorPicker
          showLabel={false}
          noBorder
          background={color}
          setBackground={color => {
            setColor(color);
            updateTextStyles("fill", `#${color}`);
          }}
        />
      </div>
    </div>
  );
};

export default ToolBar;
