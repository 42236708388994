import React, { useEffect, useState } from "react";

import { Table, Button, Alert } from "neetoui";
import { Header } from "neetoui/layouts";

import categoriesApi from "apis/categories";
import subcategoriesApi from "apis/subcategoriesApi";

import CreateEdit from "./CreateEdit";
import SubcategoriesTable from "./SubcategoriesTable";
import { columnData } from "./utils";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [category, setCategory] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);

  const getInitialData = async () => {
    const { data } = await categoriesApi.fetch();
    setCategories(data);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const deleteCategory = async id => {
    try {
      setIsDeleting(true);
      await categoriesApi.deleteCategory(id);
      getInitialData();
      setDeleteId(null);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSubmit = async (values, file) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(values));
    if (file) formData.append("file", file);

    if (isSubCategoryOpen) {
      formData.append("category_id", category);
      await subcategoriesApi.create(formData);
      getInitialData();
    } else {
      formData.append("category_id", category?.id);
      !category
        ? await categoriesApi.create(formData)
        : await categoriesApi.update(category?.id, formData);
      getInitialData();
    }
    setCategory(null);
    setIsOpen(false);
    setIsSubCategoryOpen(false);
  };

  const addSubcategory = id => {
    setIsSubCategoryOpen(true);
    setCategory(id);
  };

  return (
    <div
      className="px-10 py-10"
      style={{
        width: "calc(100vw - 75px)",
      }}
    >
      <Header
        actionBlock={
          <Button
            label="Add Category"
            size="large"
            onClick={() => setIsOpen(true)}
          />
        }
        title="Category"
      />
      <div
        style={{
          height: "calc(100vh - 254px)",
        }}
      >
        <Table
          isLoading={false}
          columnData={columnData({
            setDeleteId,
            setCategory,
            setIsOpen,
            addSubcategory,
          })}
          rowData={categories}
          defaultPageSize={100}
          currentPageNumber={1}
          handlePageChange={() => {}}
          fixedHeight
          rowSelection={false}
          expandable={{
            expandedRowRender: record => (
              <SubcategoriesTable
                subcategories={record.subcategories}
                getInitialData={getInitialData}
              />
            ),
            rowExpandable: record => record.subcategories?.length > 0,
          }}
        />
      </div>
      <Alert
        isOpen={!!deleteId}
        title="Delete category"
        message="Are you sure?"
        onClose={() => setDeleteId(null)}
        onSubmit={() => deleteCategory(deleteId)}
        isSubmitting={isDeleting}
      />
      <CreateEdit
        isOpen={isOpen}
        onClose={() => {
          setCategory(null);
          setIsOpen(false);
        }}
        onSubmit={handleSubmit}
        category={category}
      />
      <CreateEdit
        isOpen={isSubCategoryOpen}
        onClose={() => {
          setIsSubCategoryOpen(false);
          setCategory(null);
        }}
        onSubmit={handleSubmit}
        category={category}
        showSubCategory
      />
    </div>
  );
};

export default Categories;
