import React from "react";

import useQuantity from "hooks/useQuantity";

const Quantity = ({ setQuantity, quantity }) => {
  const { updateQuantity } = useQuantity({ setQuantity, quantity });

  return (
    <div className="flex items-center">
      {/* <div className="footer-text mr-2">Quantity: </div> */}
      <div className="border-grey-500 flex items-center border bg-white">
        <div
          className="border-grey-500 cursor-pointer select-none border-r px-4 py-1"
          onClick={() => updateQuantity("sub")}
        >
          -
        </div>
        <div className="cursor-none flex w-20 justify-center">{quantity}</div>
        <div
          className="border-grey-500 cursor-pointer select-none border-l px-4 py-1"
          onClick={() => updateQuantity("add")}
        >
          +
        </div>
      </div>
    </div>
  );
};

export default Quantity;
