import React, { useState } from "react";

import { Typography, Input } from "neetoui";

import DeleteSvg from "images/delete-icon.svg";

import ProductSelect from "./ProductSelect";
import Quantity from "./Quantity";

const ProductCard = ({
  productDetails,
  productQuantity = 1,
  updateLineItemQuantity,
  deleteLineItem,
  selected,
  onSelect,
}) => {
  const [quantity, setQuantity] = useState(productQuantity);

  const updateQuantity = quantity => {
    setQuantity(quantity);
    updateLineItemQuantity(quantity);
  };

  return (
    <div className="border-grey-500 product-card mb-8 w-2/3 rounded border bg-white">
      <div className="flex py-5 px-7">
        <div className="border-grey-500 mx-auto h-36 w-36 rounded border md:mx-0">
          <img
            src={productDetails.images[0].src}
            className="h-full w-full object-cover"
          />
        </div>
        <div className="ml-8 w-3/4">
          <div className="mb-7 flex justify-between">
            <Typography className="text-lg md:text-xl">
              {productDetails.title}
            </Typography>
            <div className="flex">
              <DeleteSvg
                className="mr-3 cursor-pointer"
                onClick={deleteLineItem}
              />
            </div>
          </div>
          <ProductSelect selected={selected} onSelect={onSelect} />
          <Input
            value={productDetails?.active_variant.title}
            label="Size"
            className="custom-input w-full"
            disabled={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 items-center justify-center gap-4 bg-gray-100 py-4 px-4 lg:grid-cols-3">
        <div className="flex items-center justify-center md:mb-0">
          <div className="footer-text">
            Price: $
            <strong>
              {parseFloat(productDetails?.active_variant.price).toFixed(2)}
            </strong>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Quantity setQuantity={updateQuantity} quantity={quantity} />
        </div>
        <div className="footer-text flex w-full items-center justify-center">
          <div className="w-full rounded-full bg-white px-3 py-2 sm:w-28 md:w-28">
            Cost: $
            <strong>
              {(
                parseFloat(productDetails?.active_variant.price) * quantity
              ).toFixed(2)}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
