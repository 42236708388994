import React from "react";

import NoDataSvg from "images/no-data.svg";

const NoData = () => (
  <div className="col-span-3 flex h-full flex-col items-center justify-center">
    <div className="w-1/3">
      <NoDataSvg />
    </div>
    <br />
    <div>No Data</div>
  </div>
);

export default NoData;
