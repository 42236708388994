import React, { useState, useEffect, useMemo, useCallback } from "react";

import { Spinner } from "neetoui";
import { useHistory, useParams } from "react-router-dom";

import lineItemsApi from "apis/lineItems";
import ordersApi from "apis/orders";
import productApi from "apis/printify/product";
import { getSubTotal } from "common/helpers";
import Footer from "components/Common/Footer";
import Header from "components/Common/Header";
import SubHeader from "components/Common/SubHeader";
import { PRODUCT_CART_PATH } from "components/routeConstants";

import OrderSummary from "./OrderSummary";
import ProductList from "./ProductList";
import PromoCode from "./PromoCode";
import ShippingMethod from "./ShippingMethod";

const ProductReview = () => {
  const history = useHistory();
  const { id } = useParams();

  const [order, setOrder] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(null);
  const [isLoadingPromoCode, setIsLoadingPromoCode] = useState(false);
  const [productId, setProductId] = useState(null);

  const updateTag = useCallback(async () => {
    await productApi.addTag();
  }, []);

  useEffect(() => {
    updateTag();
  }, [updateTag]);

  const getOrderDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = id
        ? await ordersApi.fetchById(id)
        : await lineItemsApi.fetch();
      setOrder(id ? data.line_items : data);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getOrderDetails();
  }, [getOrderDetails]);

  const subTotal = useMemo(() => getSubTotal(order || []), [order]);

  const redirectToCart = () => history.push(PRODUCT_CART_PATH);

  const handlePromoCodeChange = e => setPromoCode(e.target.value);

  const applyPromoCode = async () => {
    setIsLoadingPromoCode(true);
    try {
      const productIds = order?.map(
        item => item.product.active_variant.product_id
      );
      const {
        data: { discount = null, product_id: productId = null },
      } = await ordersApi.applyPromoCode({
        code: promoCode,
        productIds,
      });
      setDiscount(discount);
      setProductId(productId);
    } finally {
      setIsLoadingPromoCode(false);
    }
  };

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Header />
      <div className="cart product-review flex-1">
        <div className="px-4 sm:px-8 lg:px-24 xl:px-36">
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="container mx-auto">
              <SubHeader
                title="Product summary"
                showButton
                redirectToCart={redirectToCart}
              />
              <div className="flex flex-col justify-center space-y-8 py-6 lg:flex-row lg:space-y-0 lg:space-x-8 lg:py-10">
                <div className="w-full px-0 sm:px-5 lg:w-2/4">
                  <ShippingMethod
                    shippingMethod={shippingMethod}
                    setShippingMethod={setShippingMethod}
                  />
                  <ProductList lineItems={order} />
                </div>
                <div className="w-full px-0 sm:px-5 lg:w-2/5">
                  <OrderSummary
                    subtotal={subTotal}
                    orderId={id}
                    discount={discount?.coupon}
                    couponCode={discount?.id}
                    productId={productId}
                    order={order}
                  />
                  <PromoCode
                    onPromoCodeChange={handlePromoCodeChange}
                    promoCode={promoCode}
                    applyPromoCode={applyPromoCode}
                    isLoadingPromoCode={isLoadingPromoCode}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ProductReview;
