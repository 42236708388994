import React from "react";

import Tab from "components/Common/Tab";

const HeaderTab = ({ activeTab, setActiveTab, isLoggedIn }) => (
  <Tab className="sidebar-tab justify-around border-b border-[#E4E5E6]">
    <Tab.Item
      active={activeTab === 1}
      className="tab-item"
      onClick={() => setActiveTab(1)}
      tooltipContent="Main Product Tools"
    >
      Product Design
    </Tab.Item>
    {isLoggedIn && (
      <>
        <Tab.Item
          active={activeTab === 2}
          className="tab-item"
          onClick={() => setActiveTab(2)}
          tooltipContent="Where saved maps are stored"
        >
          My Maps
        </Tab.Item>
        <Tab.Item
          active={activeTab === 3}
          className="tab-item"
          onClick={() => setActiveTab(3)}
          tooltipContent="Where uploaded data is stored"
        >
          My Library
        </Tab.Item>
      </>
    )}
  </Tab>
);

export default HeaderTab;
