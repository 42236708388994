import React from "react";

import menuOne from "images/menu-1.jpg";
import menuThree from "images/menu-3.jpg";

import ProductTab from "./ProductTab";

const MainMenu = () => (
  <div className="h-menu-cont w-1/3">
    <nav>
      <ul className="main-menu">
        <li>
          <a href="https://boundri.com/shop/">Online Store</a>
          <div className="sub-menu-inn">
            <div className="w-1/2">
              <h3>Store categories</h3>
              <ul className="sub-menu">
                <li>
                  <a className="#">Toy play rugs</a>
                </li>
                <li>
                  <a className="#">Race tracks</a>
                </li>
                <li>
                  <a className="#">Sporting events</a>
                </li>
                <li>
                  <a className="#">World sights</a>
                </li>
              </ul>
            </div>
            <div className="w-1/2">
              <img width="180" height="252" src={menuOne} alt="" />
            </div>
          </div>
        </li>
        <ProductTab />
        <li>
          <a href="#">Info</a>
          <div className="sub-menu-inn">
            <div className="w-1/2">
              <h3>Info</h3>
              <ul className="sub-menu">
                <li>
                  <a className="#" href="https://boundri.com/faq/">
                    FAQ
                  </a>
                </li>
                <li>
                  <a className="#" href="https://boundri.com/about-us/">
                    About us
                  </a>
                </li>
                <li>
                  <a className="#">Reviews</a>
                </li>
                <li>
                  <a className="#">Report issues</a>
                </li>
                <li>
                  <a className="#">Custom Requests</a>
                </li>
                <li>
                  <a className="#" href="https://boundri.com/basic-rules/">
                    Basic Rules
                  </a>
                </li>
                <li>
                  <a className="#" href="https://boundri.com/shipping-policy/">
                    Shipping Policy
                  </a>
                </li>
                <li>
                  <a className="#" href="https://boundri.com/refund_returns/">
                    Return Policy
                  </a>
                </li>
                <li>
                  <a className="#" href="https://boundri.com/privacy-policy/">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    className="#"
                    href="https://boundri.com/terms-and-conditions/"
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a className="#" href="https://boundri.com/contact-us/">
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-1/2">
              <img width="180" height="240" src={menuThree} alt="" />
            </div>
          </div>
        </li>
        <li>
          <button
            className="neeto-ui-btn neeto-ui-btn--style-primary neeto-ui-btn--size-medium header-login-btn"
            type="button"
          >
            Sign in
          </button>
        </li>
      </ul>
    </nav>
  </div>
);

export default MainMenu;
