import React, { useEffect, useState } from "react";

import { Button, Typography, Spinner, Alert } from "neetoui";
import { useHistory } from "react-router-dom";

import cartsApi from "apis/cart";
import lineItemsApi from "apis/lineItems";
import Footer from "components/Common/Footer";
import Header from "components/Common/Header";
import { PRODUCT_REVIEW_PATH } from "components/routeConstants";
import EmptyCart from "images/empty-cart.svg";
import NoDataSvg from "images/no-data.svg";

import ProductCard from "./ProductCard";

const ProductCart = () => {
  const history = useHistory();

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const getCartsList = async () => {
    setIsLoading(true);
    const selectedItems = {};
    const { data: cart } = await cartsApi.fetch();
    const lineItems = cart.line_items;
    lineItems.forEach(item => {
      selectedItems[item.id] = {
        variantId: item.product.active_variant.id,
        selected: true,
      };
    });
    setProducts(lineItems);
    setSelectedProducts(selectedItems);
    setIsLoading(false);
  };

  useEffect(() => {
    getCartsList();
  }, []);

  const handleSelect = (isSelected, id) => {
    const cSelectedProducts = { ...selectedProducts };
    cSelectedProducts[id] = {
      ...(cSelectedProducts[id] || {}),
      selected: isSelected,
      variantId: cSelectedProducts[id]?.variant_id,
    };
    setSelectedProducts(cSelectedProducts);
  };

  const clearCart = async () => {
    const ids = products.map(({ id }) => id);
    await lineItemsApi.bulkDelete({ ids });
    setProducts([]);
  };

  const handleShippingClick = async () => {
    try {
      const payload = Object.keys(selectedProducts).map(key => ({
        id: key,
        variant_id: selectedProducts[key].variantId,
        selected: selectedProducts[key].selected,
        quantity: selectedProducts[key].quantity || 1,
      }));
      await lineItemsApi.bulkUpdate(payload);
      history.push(PRODUCT_REVIEW_PATH);
    } catch (err) {
      logger.error(err);
    }
  };

  const updateLineItemQuantity = (id, quantity) => {
    const cSelectedProducts = { ...selectedProducts };
    cSelectedProducts[id] = {
      ...(cSelectedProducts[id] || {}),
      quantity,
    };
    setSelectedProducts(cSelectedProducts);
  };

  const deleteLineItem = async () => {
    await lineItemsApi.destroy(deleteId);
    setShowAlert(false);
    setDeleteId(null);
    getCartsList();
  };

  return (
    <div className="h-full w-full">
      <Header />
      <div className="cart">
        {isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="px-24 lg:px-36">
            <div className="container mx-auto h-full">
              <div className="flex items-center justify-between pt-10">
                <Typography style="h2">Cart</Typography>
                {products.length > 0 && (
                  <div
                    className="flex cursor-pointer items-center"
                    onClick={clearCart}
                  >
                    <span className="text-xs text-gray-500">clear cart</span>{" "}
                    <EmptyCart className="ml-2" />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center py-14">
                {products.length > 0 ? (
                  <>
                    {products?.map(({ id, product, quantity }) => (
                      <ProductCard
                        key={product.id}
                        selected={!!selectedProducts[id]?.selected}
                        onSelect={isSelected => handleSelect(isSelected, id)}
                        productDetails={product}
                        productQuantity={quantity}
                        updateLineItemQuantity={quantity =>
                          updateLineItemQuantity(id, quantity)
                        }
                        deleteLineItem={() => {
                          setDeleteId(id);
                          setShowAlert(true);
                        }}
                      />
                    ))}
                    <div className="my-5 w-2/3 text-justify text-xs font-bold">
                      <span className="a mr-1 text-justify text-red-500">
                        Note for shipments to Canada:
                      </span>{" "}
                      Due to customs, duty tax will need to be taken care of by
                      the customer. Typically your local carrier will try to
                      contact you via your phone number provided. If they can’t
                      get ahold of you by phone, they will send an email. Your
                      shipment won’t arrive unless this is taken care of. Please
                      check your voicemail or junk mail just incase. Let us know
                      if you have any questions. Thanks
                    </div>
                    <div className="flex w-2/3 justify-end">
                      <Button
                        label="Proceed to payment and shipping"
                        className="rounded-full"
                        onClick={handleShippingClick}
                      />
                    </div>
                  </>
                ) : (
                  <div className="m-auto flex h-full w-1/4 flex-col items-center justify-center">
                    <NoDataSvg />
                    <br />
                    <div>No Items</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
      <Alert
        isOpen={showAlert}
        title="Delete product?"
        message="Are you sure?"
        onClose={() => setShowAlert(false)}
        onSubmit={deleteLineItem}
      />
    </div>
  );
};

export default ProductCart;
