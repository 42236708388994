import React from "react";

import DOMPurify from "dompurify";
import { Typography, Button } from "neetoui";

const Details = ({ title, description, handleClick, price }) => {
  const sanitize = DOMPurify.sanitize;
  return (
    <>
      <div className="inline-block flex justify-between">
        <Typography style="h1" className="mb-4 w-3/5">
          {title}
        </Typography>
        <div>
          <Button
            label="Build it now"
            className="m-2 rounded-full px-4 py-4"
            onClick={handleClick}
          />
        </div>
      </div>
      <Typography style="h3" weight="normal" className="mb-4">
        Starting at <span className="text-gray-500">${price}</span> USD
      </Typography>
      <Typography weight="light" className="mb-4 text-gray-500" style="body1">
        <div dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
      </Typography>
    </>
  );
};

export default Details;
